import { GET_EXTRA_DATA } from './action-types';
import { GET_PARK_RESULTS } from '../park/results/action-types';
import { SUCCESS } from '../../constants';
import { GET_BOOKINGS } from '../../user/action-types';
// import { arrayToObject } from '../../../utils/utils'

const initialState = {
    extraData: {},
    parkExtraDataIds: {},
    unitTypes: {},
};

function convertExtraData(data) {
    const output = {};
    data.forEach((item) => {
        item.code.forEach((unitType) => {
            output[unitType] = {
                ...item,
                code: unitType,
                image: item.image
                    ? `https://content.breakfreeholidays.co.uk/images/accom-types/${item.image}`
                    : item.image,
            };
        });
    });
    return output;
}

function convertUnitTypes(data) {
    const output = {};
    data.forEach((item) => {
        output[item.unitType] = {
            ...item,
            // FIXME: The endpoint needs to be updated to provide the below!
            // description: 'The information required for this line is not yet provided by the endpoint. This component will be updated as soon as it is.',
            // features: [
            //     '12ft wide',
            //     '4pm Check-in',
            //     'Heating in the living room only',
            //     'Duvets pillows and bed linen provided plus beds made on arrival',
            //     'Fully equipped kitchen',
            //     'Gas water electricity and entertainment passes included with direct bookings',
            //     'TV in the lounge - minimum 18"'
            // ],
            // sleeps: 'This accommodation has 1 double bedroom and 1 twin bedroom with additional sleeping for 2 in the lounge.'
        };
    });
    return output;
}

function getExtraDataIds(data) {
    const output = new Set();
    data.forEach((item) => {
        item.code.forEach((unitType) => {
            output.add(unitType);
        });
    });
    return [...output];
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EXTRA_DATA + SUCCESS:
            return {
                ...state,
                extraData: {
                    ...state.extraData,
                    ...convertExtraData(action.payload.response.data),
                },
                parkExtraDataIds: {
                    ...state.parkExtraDataIds,
                    [action.payload.params.parkId]: getExtraDataIds(action.payload.response.data),
                },
            };
        case GET_PARK_RESULTS + SUCCESS:
        case GET_BOOKINGS + SUCCESS:
            return {
                ...state,
                unitTypes: {
                    ...state.unitTypes,
                    // ...arrayToObject(action.payload.response.data.data.unitTypes, 'unitType')
                    ...convertUnitTypes(action.payload.response.data.data.unitTypes),
                },
            };
        default:
            return state;
    }
};

export default reducer;
