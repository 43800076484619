import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import SingleLocation from '../../components/Maps/SingleLocation';
import t from '../../../text';
import { parkBubbles } from '../../redux/data/park/reviews/selectors';

const fixLocation = (location) => {
    if (location.charAt(0) === ',') {
        return location.slice(1).replace(/^\s+|\s+$/, '');
    }
    return location;
};

function ParkTitle(props) {
    const [show, setShow] = useState(false);
    const mapAvailable = !(parseFloat(props.lat).toString() === '0' && parseFloat(props.lon).toString() === '0');
    let locationFix = fixLocation(props.location); // Fix location.  TODO: Can this be fixed in the backend?
    const _bubbles = useSelector(parkBubbles);

    return (
        <Container fluid className="bg-lighter border-bottom mb-4 py-4">
            <Container>
                <Row className="align-items-center">
                    <Col md={8}>
                        <h1>{props.name}</h1>
                        <p>
                            <i className="icon-map-marker text-primary"></i> {locationFix}
                        </p>
                    </Col>

                    <Col md={4} className="text-md-right">
                        {mapAvailable && (
                            <Button
                                variant="light"
                                className={`bg-white px-3 ${props.showReviewsBadge ? 'mr-3' : ''}`}
                                onClick={() => setShow(true)}
                            >
                                <i className="icon-map-marker"></i> {t('buttons.map')}
                            </Button>
                        )}

                        {props.showReviewsBadge && <img src={_bubbles} alt={_bubbles} />}

                        {mapAvailable && (
                            <Modal show={show} onHide={() => setShow(false)} size="lg" centered>
                                <Modal.Header closeButton>
                                    <Modal.Title>{props.name}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="p-0 rounded-bottom overflow-hidden">
                                    <SingleLocation position={props.position} name={props.name} opid={props.opid} />
                                </Modal.Body>
                            </Modal>
                        )}
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

ParkTitle.propTypes = {
    name: PropTypes.string,
    location: PropTypes.string,
    position: PropTypes.object,
    sku: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    opid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showReviewsBadge: PropTypes.bool,
};

ParkTitle.defaultProps = {
    position: { lat: 0, lng: 0 },
    showReviewsBadge: true,
};

export default ParkTitle;
