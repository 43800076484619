import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal } from 'react-bootstrap';
import t from '../../../../text';

export const AccommodationModal = ({ holiday, unitFeatures, onHide, show }) => (
    <Modal show={show} onHide={onHide} centered size="lg">
        <Modal.Header closeButton>
            <Modal.Title>{holiday.unitDesc}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Row xs={1} md={holiday.image !== '' ? 2 : 1}>
                {holiday.image !== '' && (
                    <Col>
                        <img src={holiday.image} alt={holiday.unitDesc} className="w-100" loading="lazy" />
                    </Col>
                )}
                <Col>
                    <h3>{t('park.accommodation.description')}</h3>
                    <p>{holiday.desc}</p>
                    <h3>{t('park.accommodation.sleeps')}</h3>
                    <p>{holiday.sleeps}</p>
                    {holiday.features !== '' && (
                        <>
                            <h3>{t('park.accommodation.features')}</h3>
                            {unitFeatures}
                        </>
                    )}
                </Col>
            </Row>
        </Modal.Body>
    </Modal>
);

AccommodationModal.propTypes = {
    holiday: PropTypes.object,
    onHide: PropTypes.func,
    show: PropTypes.bool,
    unitFeatures: PropTypes.array,
};

export default AccommodationModal;
