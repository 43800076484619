//import { useSelector } from 'react-redux';
//import { filters as standardFilters } from '../../redux/data/filters/selectors';

const sortOptions = [
    {
        label: 'Price Low to High',
        field: 'minPrice',
        altField: 'altDateMinPrice',
        direction: 'asc',
    },
    {
        label: 'Price High to Low',
        field: 'minPrice',
        altField: 'altDateMinPrice',
        direction: 'desc',
    },
    {
        label: 'Name',
        field: 'name',
        direction: 'asc',
    },
];

//function applyStandardFilters(standardFilters, selectedFilters, results) {
//    if (!selectedFilters.length) return results;
//    const filtersToApply = standardFilters.filter((filter) => filter.selected);
//    const filterMask = filtersToApply.reduce((acc, val) => acc | val.filt, 0);
//    return results.filter((result) => parseInt(result.park.filt & filterMask) === filterMask);
//}

function applyOperatorFilters(selectedFilters, results) {
    if (!selectedFilters.length) return results;
    return results.filter((result) => selectedFilters.includes(result.park.opId));
}

function applyPetFriendlyFilters(selectedFilters, results) {
    if (!selectedFilters.length || (selectedFilters[0].toLowerCase() === 'n' && selectedFilters.length === 1))
        return results;
    return results.filter((result) => result.park.dogs === 'Y');
}

function applyNFilters(nFilters, results, compareTypes) {
    if (!nFilters || !nFilters.length) return results;

    const compareArray = compareTypes.map((filt) => filt.compare);
    const nFiltersFixed = [...nFilters];

    return results.filter((result) => {
        let makeArray = Array.isArray(result.park.nFilt) ? result.park.nFilt : [];

        let parkFilts = fixNFilt(makeArray);
        nFilters = nFiltersFixed;

        parkFilts = parkFilts.map((num, index) => {
            const relevantNFilt = nFilters[index];
            const compare = compareArray[index];
            if (relevantNFilt === -1) return true;
            return compareTwoNumbers(num, compare, relevantNFilt);
        });

        // Are all parkFilt values true?
        return parkFilts.every((x) => x);
    });
}

function applyBFilters(selectedBFilters, results) {
    selectedBFilters.map(
        (filter) => (results = results.filter((result) => parseInt(result.park.bFilt) & parseInt(filter))),
    );
    return results;
}

function applyTownFilter(townId, results) {
    return results.filter((result) => result.park.towns.includes(+townId));
}

const compareTwoNumbers = (num1, compare, num2) => {
    switch (compare) {
        case '>=':
            return num1 >= num2;
        case '>':
            return num1 > num2;
        case '==':
            return num1 === num2;
        case '<':
            return num1 < num2;
        case '<=':
            return num1 <= num2;
        default:
            return true;
    }
};

function applySort(selectedSort, results) {
    if (!selectedSort || !results) return results;

    const { field, altField, direction } = selectedSort;

    if (field === 'name') {
        results.sort((a, b) => {
            if (a.park.pri === 1 && b.park.pri !== 1) return -1;
            if (a.park.pri !== 1 && b.park.pri === 1) return 1;
            return a.park.name.localeCompare(b.park.name);
        });
        return;
    }

    if (direction === 'asc') {
        results.sort((a, b) => {
            if (a.park.pri === 1 && b.park.pri !== 1) return -1;
            if (a.park.pri !== 1 && b.park.pri === 1) return 1;
            return parseFloat(a[field] || a[altField]) - parseFloat(b[field] || b[altField]);
        });
    } else {
        results.sort((a, b) => {
            if (a.park.pri === 1 && b.park.pri !== 1) return -1;
            if (a.park.pri !== 1 && b.park.pri === 1) return 1;
            return parseFloat(b[field] || b[altField]) - parseFloat(a[field] || a[altField]);
        });
    }
}

function cleanSelectedOperatorsFilters(selectedFilters, results) {
    if (!results) return results;
    const operatorIds = [
        ...new Set(
            results
                .map((result) => result.operator)
                .filter((operator) => !!operator)
                .map((operator) => operator.opId),
        ),
    ];

    return selectedFilters.filter((id) => operatorIds.includes(id));
}

function getOperatorFilters(results, selectedOperators) {
    if (!results) return results;
    const operators = new Set(results.map((result) => result.operator).filter((operator) => !!operator));

    return [...operators]
        .map((operator) => ({
            ...operator,
            selected: selectedOperators.includes(operator.opId),
        }))
        .sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });
}

function getPetFriendlyFilters(selectedFilters) {
    return [
        {
            id: 'y',
            name: 'Pet friendly',
            selected: selectedFilters.includes('y'),
        },
    ];
}

//function getStandardFilters(filters, selectedFilters) {
//    if (!filters) return filters;
//    return Object.keys(filters).map((filterId) => ({
//        ...filters[filterId],
//        selected: selectedFilters.includes(filterId),
//    }));
//}

// Convert ["2", "", "2"] => [2,2] because bad data currently
// Will leave a correct array alone [4, 3] wont be altered for example
function fixNFilt(arr = []) {
    return arr.filter((num) => num !== '').map((n) => +n);
}

//function findMaxValue(results, index) {
//    if (!results.length) return [];
//    let maxValue = 0;
//    results.forEach((result) => {
//        let neverNull = result.park.nFilt === null ? [] : result.park.nFilt;
//        const arr = fixNFilt(neverNull);
//        if (arr[index] > maxValue) {
//            maxValue = arr[index];
//        }
//    });
//    return maxValue;
//}

//function findAndSortUniqueValues(results, index) {
//    const allValues = results.map((r) => fixNFilt(r.park.nFilt)[index]);
//    return [...new Set(allValues.sort())];
//}

// Because this is dynamic, make an array of arrays of objects,
// Inner array is each filter panel,
// Object is an option { id: 1, label: '1' }
//function makeNFilters(nFilt, results) {
//    if (!nFilt.length) return;
//
//    const compareArray = nFilt.map((filt) => filt.compare);
//    const filterList = [];
//
//    for (let ca = 0; ca < compareArray.length; ca++) {
//        let labelSuffix = '';
//        if (compareArray[ca] === '>=') labelSuffix = ' +'; // replace with switch block when need arises?
//
//        // Calculate for all non equals comparisons
//        if (compareArray[ca] !== '==') {
//            const maxValue = findMaxValue(results, ca);
//            const tempFilters = [];
//
//            for (let i = 1; i <= maxValue; i++) {
//                tempFilters.push({ id: i, label: `${i}${labelSuffix}` });
//            }
//            filterList.push(tempFilters);
//        }
//
//        // Calculate for equals comparisons
//        if (compareArray[ca] === '==') {
//            const neededValues = findAndSortUniqueValues(results, ca);
//            const tempFilters = [];
//
//            for (let i = 0; i < neededValues.length; i++) {
//                const value = neededValues[i];
//                tempFilters.push({ id: value, label: `${value}` });
//            }
//            filterList.push(tempFilters);
//        }
//    }
//    return filterList;
//}

export function useFilters(
    results, // 0
    channelId, // 1
    //selectedStandardFilters = [],     // 2
    selectedOperatorFilters = [], // 3
    selectedPetFriendlyFilters = [], // 4
    selectedNFilters = [], // 5
    selectedBFilters = [], // 6
    nFilt = [], // 7
    appliedSort = sortOptions[0], // 8
    sortByName, // 9
    isTown, // 10
) {
    //const _standardFiltersObj = useSelector(standardFilters);
    //const fixedResults = [...results];

    // Ensure we dont try and filter by operator Ids that are not present in the results set
    const _selectedOperatorFilters = cleanSelectedOperatorsFilters(selectedOperatorFilters, results);

    //const _standardFilters = getStandardFilters(_standardFiltersObj, selectedStandardFilters);
    const _operatorFilters = getOperatorFilters(results, _selectedOperatorFilters);
    const _petFriendlyFilters = getPetFriendlyFilters(selectedPetFriendlyFilters);
    //let _nFilters;
    //let _bFilters = [];
    //if (channelId === 'ukcottages') {
    //_nFilters = makeNFilters(nFilt, fixedResults);
    //}

    // Filtering
    let newResults = applyOperatorFilters(_selectedOperatorFilters, results);
    //if (channelId !== 'boats') {
    //    newResults = applyStandardFilters(_standardFilters, selectedStandardFilters, newResults);
    //}
    if (channelId !== 'eurocaravan') {
        newResults = applyPetFriendlyFilters(selectedPetFriendlyFilters, newResults);
    }
    //if (channelId === 'ukcottages') {
    newResults = applyNFilters(selectedNFilters, newResults, nFilt);
    newResults = applyBFilters(selectedBFilters, newResults);
    //}

    // If is a town page, exclude other towns (isTown is either false or the townId)
    if (isTown) {
        newResults = applyTownFilter(isTown, newResults);
    }

    // Sorting
    if (appliedSort) {
        applySort(appliedSort || sortOptions[0], newResults);
    }

    if (sortByName) {
        newResults.sort((a, b) => {
            if (a.park.pri === 1 && b.park.pri !== 1) return -1;
            if (a.park.pri !== 1 && b.park.pri === 1) return 1;
            return a.park.name.localeCompare(b.park.name);
        });
    }

    return [_operatorFilters, _petFriendlyFilters, sortOptions, newResults];
}

export default useFilters;
