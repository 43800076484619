import {
    COMPLETE_BOOKING,
    CREATE_BOOKING,
    GET_DEPOSIT,
    SET_DISCOUNT_CODE,
    SET_DISCOUNT_VALUE,
    LOAD_BOOKING,
    STEP_COMPLETED,
    STEP_NOT_COMPLETED,
} from './action-types';
import { apiRequest } from '../../apiRequest';
import selfCancellingAction from '../../selfCancellingAction';
import { urls } from '../../../../config';

export const [createBooking, cancelCreateBooking] = selfCancellingAction(
    (dispatch, channelId, parkId, unitId, unitTypeId, unitPrice, arrivalDate, duration, party = {}) => {
        dispatch({
            type: CREATE_BOOKING,
            payload: {
                type: 'new',
                channelId,
                unitId: unitId,
                unitTypeId,
                unitPrice,
                parkId,
                arrivalDate,
                duration,
                party,
                discountValue: null,
            },
        });
        return apiRequest(dispatch, CREATE_BOOKING, urls.booking, { unitId: unitId, ...party });
    },
);

export const [getDeposit, cancelGetDeposit] = selfCancellingAction((dispatch, bookingId) => {
    return apiRequest(dispatch, GET_DEPOSIT, urls.bookingGetDeposit, { bookId: bookingId });
});

export const storeDiscountCode = (dispatch, code) => {
    return dispatch({
        type: SET_DISCOUNT_CODE,
        payload: code.toString(),
    });
};

export const storeDiscountValue = (dispatch, value) => {
    return dispatch({
        type: SET_DISCOUNT_VALUE,
        payload: parseFloat(value),
    });
};

export const completeBooking = (dispatch) => {
    return dispatch({
        type: COMPLETE_BOOKING,
        payload: {},
    });
};

export const loadBooking = (
    dispatch,
    bookingId,
    channelId,
    parkId,
    unitId,
    unitTypeId,
    totalCost,
    amountPaid,
    arrivalDate,
    duration,
) => {
    dispatch({
        type: LOAD_BOOKING,
        payload: {
            type: 'existing',
            bookingId,
            channelId,
            unitId: unitId,
            unitTypeId,
            totalCost,
            amountPaid,
            parkId,
            arrivalDate,
            duration,
        },
    });
};

export const stepCompleted = (dispatch, step) => {
    dispatch({ type: STEP_COMPLETED, payload: step });
};

export const stepNotCompleted = (dispatch, step) => {
    dispatch({ type: STEP_NOT_COMPLETED, payload: step });
};
