import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { channelId, details, operator, parkId, processedResults, reviews } from '../../redux/park/selectors';
import { urls } from '../../../config';
import Accommodation from '../../components/ParkPage/ParkInformation/Accommodation';
import AlternativeDatesSection from './AlternativeDatesSection';
import Facilities from '../../components/ParkPage/ParkInformation/Facilities';
import ImportantInfo from '../../components/ParkPage/ParkInformation/ImportantInfo';
import ParkInformationMenu from '../../components/ParkPage/ParkInformation/ParkInformationMenu';
import RedEyeParkResultsTag from '../../components/RedEye/RedEyeParkResultsTag';
import Reviews from '../../components/ParkPage/ParkInformation/Reviews';
import WithLoadingStates from '../../components/Booking/WithLoadingStates';
import WithParkResults from '../../components/HOC/WithParkResults';
import WithParkReviews from '../../components/HOC/WithParkReviews';
import WithUnitTypesExtraData from '../../components/HOC/WithUnitTypesExtraData';
import WithUpdatedSearchParams from '../../components/HOC/WithUpdatedSearchParams';
import t from '../../../text';
import ThingsToDo from '../../components/ParkPage/ParkInformation/ThingsToDo';
import Videos from '../../components/ParkPage/ParkInformation/Videos';
import QandA from '../../components/ParkPage/ParkInformation/QandA';
import Location from '../../components/ParkPage/ParkInformation/Location';
import AltAccommodation from '../../components/ParkPage/ParkInformation/AltAccommodation';
import ChannelExtra from '../../components/ParkPage/ParkInformation/ChannelExtra';

function ParkInformation({ onNext, searchResult }) {
    const _channelId = useSelector(channelId);
    const _operator = useSelector(operator);
    const _parkDetails = useSelector(details);
    const _parkId = useSelector(parkId);
    const _processedResults = useSelector(processedResults);
    const _reviews = useSelector(reviews);
    const RegionName = useSelector((state) => state.data.regions.regions);
    const selectedResult = _processedResults?.selectedDate;
    const isBoat = _channelId === 'boats';
    const showAccommodation = searchResult;
    const parkObject = useSelector(details);
    const showImportantInfo = parkObject.importantInfo.length > 0;

    // Video
    const videos = parkObject.vid ? parkObject.vid.split(/\r?\n/) : [];
    const videoCount = videos.length;

    // Q&A
    const showQA = parkObject.qa.length > 0;
    const qaContent = parkObject.qa;

    // Location
    const position = parkObject.pos;
    const directions = parkObject.directions;
    const showLocation = position.length > 0 || directions.length > 0;

    // Alt Accommodation
    let showAltAccommodation = !showAccommodation;
    if (_parkDetails.accommodation.length === 0) showAltAccommodation = false;

    // State
    let findActiveTab = 'facilities';
    if (showAccommodation) findActiveTab = 'accommodation';
    if (showAltAccommodation) findActiveTab = 'altaccommodation';

    const [activeTab, setActiveTab] = useState(findActiveTab);
    const [ttdData, setTtdData] = useState([]);
    const [ttdPosts, setTtdPosts] = useState([]);
    const handleMenuClick = (key) => {
        setActiveTab(key);
    };

    useEffect(() => {
        const controller = new AbortController();
        fetch(
            `${urls.cms}/travel-guides/?filter[meta_key]=region-id&filter[meta_value]=${RegionName[_parkDetails.regn].id
            }&_fields=acf`,
            { signal: controller.signal },
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.length > 0) {
                    const wpData = data[0];
                    setTtdData(wpData.acf['things-to-do']);
                }
            })
            .catch((e) => {
                console.error('Could not fetch the content.', e);
            });
        return () => {
            controller.abort();
        };
    }, [RegionName, _parkDetails.regn]);

    useEffect(() => {
        const controller = new AbortController();
        // And now, we get the things to do:
        let ttdUrl = `${urls.cms}/things-to-do/?_fields=title,acf`;
        ttdData.forEach((ttd) => {
            ttdUrl = ttdUrl + '&include[]=' + ttd;
        });
        if (ttdData.length > 0) {
            fetch(ttdUrl, { signal: controller.signal })
                .then((response) => response.json())
                .then((data) => {
                    setTtdPosts(data);
                })
                .catch((e) => {
                    console.error('Could not fetch the content.', e);
                });
        }
        return () => {
            controller.abort();
        };
    }, [ttdData]);

    if (!isBoat) {
        document.getElementsByTagName('meta')['description'].content = t(
            'park.info.metaDescription',
            _parkDetails.name,
            RegionName[_parkDetails.regn].name,
        );
    }

    const getContent = () => {
        switch (activeTab) {
            case 'accommodation':
                return (
                    <WithParkResults parkId={_parkId}>
                        <WithUnitTypesExtraData
                            parkId={_parkId}
                            parkName={_parkDetails.name}
                            operatorName={_operator.name}
                        >
                            <WithLoadingStates spinnerMessage={t('park.info.loadingAccommodation')}>
                                <Accommodation
                                    result={selectedResult}
                                    parkDetails={_parkDetails}
                                    onBook={(unit) => onNext({ unit })}
                                />
                            </WithLoadingStates>
                        </WithUnitTypesExtraData>
                    </WithParkResults>
                );
            case 'altaccommodation':
                return <AltAccommodation content={_parkDetails.accommodation} channel={_channelId} />;
            case 'facilities':
                return (
                    <Facilities
                        facilities={_parkDetails.facilities}
                        importantInfo={!isBoat ? _parkDetails.covidDets : undefined}
                        overview={_parkDetails.facilOverview}
                        title={_channelId === 'ukcottages' ? 'Property Information' : `${t('park.info.facilities')}`}
                    />
                );
            case 'reviews':
                return (
                    <WithLoadingStates spinnerMessage={t('park.info.loadingReviews')}>
                        <Reviews parkDetails={_parkDetails} reviews={_reviews} />
                    </WithLoadingStates>
                );
            case 'importantInfo':
                return <ImportantInfo content={_parkDetails.importantInfo} />;
            case 'ttd':
                return <ThingsToDo ttdPosts={ttdPosts} />;
            case 'videos':
                return <Videos content={videos} count={videoCount} />;
            case 'qa':
                return <QandA content={qaContent} />;
            case 'location':
                return (
                    <Location
                        position={position}
                        directions={directions}
                        name={parkObject.name}
                        opid={parkObject.opid}
                    />
                );
            case 'channelExtra':
                return <ChannelExtra channel={_channelId} />;
            default:
                return <div></div>;
        }
    };

    return (
        <>
            {searchResult && (
                <AlternativeDatesSection
                    clicked={handleMenuClick}
                    active={activeTab}
                    channel={_channelId}
                    pid={_parkId}
                />
            )}
            <Container className="py-5 mb-5">
                <Row>
                    <Col md={3}>
                        <WithUpdatedSearchParams channelId={_channelId} regionId={_parkDetails.regn} />
                        <ParkInformationMenu
                            active={activeTab}
                            showAccommodation={showAccommodation}
                            showAltAccommodation={showAltAccommodation}
                            showImportantInfo={showImportantInfo}
                            videoCount={videoCount}
                            showQA={showQA}
                            showLocation={showLocation}
                            showThingsToDo={ttdPosts.length > 0}
                            clicked={handleMenuClick}
                            regionId={_parkDetails.regn}
                            channel={_channelId}
                        />
                    </Col>
                    <Col md={9}>
                        <WithParkReviews parkId={_parkId}>
                            {getContent()}
                        </WithParkReviews>
                        {/* Redeye tag */}
                        {showAccommodation && (
                            <div className="d-none">
                                <WithParkResults parkId={_parkId}>
                                    <WithUnitTypesExtraData
                                        parkId={_parkId}
                                        parkName={_parkDetails.name}
                                        operatorName={_operator.name}
                                    >
                                        <WithLoadingStates>
                                            <RedEyeParkResultsTag />
                                        </WithLoadingStates>
                                    </WithUnitTypesExtraData>
                                </WithParkResults>
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ParkInformation;
