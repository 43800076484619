import React from 'react';
import { Form, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import InputRadioButton from '../../../FormElements/InputRadioButton';
import PartyDetailsFormRow from './PartyDetailsFormRow';
import t from '../../../../../text';

// import { required, email, telephone, postcode }  from '../../utils/validation';

export const validateFields = (values = {}) => {
    const errors = {};
    // if(!required(values.title)) {
    //     errors.title = 'Please select a value';
    // }
    return errors;
};

export const isValid = (values = {}) => !Object.keys(validateFields(values)).length;

export const PartyDetailsForm = ({
    adults,
    children,
    under5s,
    errors,
    values,
    areYouGoing,
    onChangePartyDetail,
    onSelectAreYouGoing,
    showAreYouGoing,
    dob,
}) => {
    return (
        <Form name="partyDetailsForm" noValidate>
            {showAreYouGoing && (
                <Form.Row>
                    <Form.Group as={Col} className="mb-4">
                        <p className="mb-2">{t('forms.party.areYouGoing')}</p>
                        <div className="d-inline-block mb-2 mr-2 pr-1">
                            <InputRadioButton
                                name="areYouGoing"
                                value="Y"
                                label={t('forms.forms.yes')}
                                checked={areYouGoing === 'Y'}
                                onChange={(key, value) => onSelectAreYouGoing(key, value)}
                            />
                        </div>
                        <div className="d-inline-block">
                            <InputRadioButton
                                name="areYouGoing"
                                value="N"
                                label={t('forms.party.noIAmOnlyMakingTheBooking')}
                                checked={areYouGoing === 'N'}
                                onChange={(key, value) => onSelectAreYouGoing(key, value)}
                            />
                        </div>
                    </Form.Group>
                </Form.Row>
            )}

            <p>{t('forms.party.pleaseFillInDetails')}</p>

            {adults ? (
                <>
                    <h4>{t('forms.party.adults')}</h4>
                    {[...new Array(adults)].map((item, index) => (
                        <PartyDetailsFormRow
                            key={index}
                            values={values}
                            errors={errors}
                            onChange={onChangePartyDetail}
                            ageGroup="adults"
                            suffix={index + 1}
                            locked={index === 0 && areYouGoing === 'Y'}
                            dob={dob}
                        />
                    ))}
                </>
            ) : null}
            {children ? (
                <>
                    <h4>{t('forms.party.children')}</h4>
                    {[...new Array(children)].map((item, index) => (
                        <PartyDetailsFormRow
                            key={index}
                            values={values}
                            errors={errors}
                            onChange={onChangePartyDetail}
                            suffix={index + adults + 1}
                            ageGroup="children"
                            dob={dob}
                        />
                    ))}
                </>
            ) : null}
            {under5s ? (
                <>
                    <h4>{t('forms.party.infants')}</h4>
                    {[...new Array(under5s)].map((item, index) => (
                        <PartyDetailsFormRow
                            key={index}
                            values={values}
                            errors={errors}
                            onChange={onChangePartyDetail}
                            suffix={index + adults + children + 1}
                            ageGroup="under5s"
                            dob={dob}
                        />
                    ))}
                </>
            ) : null}
        </Form>
    );
};

PartyDetailsForm.defaultProps = {
    adults: 0,
    children: 0,
    under5s: 0,
    values: {},
    errors: {},
    showAreYouGoing: true,
};

PartyDetailsForm.propTypes = {
    adults: PropTypes.number,
    children: PropTypes.number,
    under5s: PropTypes.number,
    areYouGoing: PropTypes.string,
    values: PropTypes.object,
    errors: PropTypes.object,
    onChangePartyDetail: PropTypes.func,
    onSelectAreYouGoing: PropTypes.func,
    showAreYouGoing: PropTypes.bool,
    dob: PropTypes.bool,
};

export default PartyDetailsForm;
