import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import PageTitle from '../../components/PageTitle';
import ScrollToTop from '../../components/ScrollToTop';
import { urls } from '../../../config';
import t from '../../../text';
import SearchBox from '../../../base/components/SearchBox/SearchBox';
import Axios from 'axios';

const FetchContent = ({ post, contentData }) => {
    const [theData, setTheData] = useState(false);
    const [postData, setPostData] = useState('Just getting your content...');
    const [titleData, setTitleData] = useState('Loading...');
    const [metaTitle, setMetaTitle] = useState('Loading...');
    const [metaDesc, setMetaDesc] = useState('');
    const [showSearchBox, setShowSearchBox] = useState(false);
    const location = useLocation();
    let { contentType, contentId } = useParams();

    if (post && post.length) {
        contentType = post.split('/')[0];
        contentId = post.split('/')[1];
    }
    if (contentData && contentData.length) contentType = contentData;

    useEffect(() => {
        const source = Axios.CancelToken.source();
        Axios.get(
            `${urls.cms}/p${contentType}s/${contentId}?_fields=id,date,title,content,acf`,
            {cancelToken: source.token}
            )
            .then((response) => {
                setTheData(response.data);
            });
        return () => {source.cancel()};
    }, [contentId, contentType]);

    useEffect(() => {
        if (!theData) return;
        setPostData(theData.content.rendered || 'Sorry, there was a problem fetching the content.  Please try again.');
        setTitleData(theData.title.rendered || 'Could not fetch the content.');
        setMetaTitle(theData.acf.document_title || theData.title.rendered);
        setMetaDesc(
            theData.acf.meta_description !== null ? theData.acf.meta_description : t('global.page.defaultMetaDesc'),
        );
        if (theData.acf.show_search_box) setShowSearchBox(theData.acf.show_search_box);
    }, [theData]);

    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDesc} />
                <link
                    rel="canonical"
                    href={`https://www.breakfreeholidays.co.uk${location.pathname}${location.search}`}
                />
            </Helmet>
            <ScrollToTop />
            {showSearchBox && (
                <Container fluid className="py-4 bg-dark">
                    <SearchBox />
                </Container>
            )}
            <PageTitle title={titleData} />
            <Container className="mb-5">
                <Row>
                    <Col dangerouslySetInnerHTML={{ __html: postData }} style={{ overflowWrap: 'anywhere' }}></Col>
                </Row>
            </Container>
        </>
    );
};

export default FetchContent;
