import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

export const CardPanel = (props) => (
    <Card className={`bf-filter ${props.className}`}>
        {!props.noTitle && <Card.Header className="h3">{props.title}</Card.Header>}

        <Card.Body>{props.children}</Card.Body>
    </Card>
);

CardPanel.defaultProps = {
    className: '',
    title: '',
    noTitle: false,
};

CardPanel.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    noTitle: PropTypes.bool,
};

export default CardPanel;
