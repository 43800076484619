import { getRequestStatus } from '../requests/selectors';
import {
    CHANGE_PASSWORD,
    CREATE_USER,
    GET_BOOKINGS,
    LOGIN,
    LOGOUT,
    REQUEST_PASSWORD_RESET,
    UPDATE_USER,
} from './action-types';

export const loggedIn = (state) => state.user.loggedIn;

export const redeyeUrl = (state) => state.user.details.redeyeUrl;
export const title = (state) => state.user.details.title;
export const firstName = (state) => state.user.details.firstName;
export const lastName = (state) => state.user.details.lastName;
export const email = (state) => state.user.details.email;
export const addrLn1 = (state) => state.user.details.addrLn1;
export const addrLn2 = (state) => state.user.details.addrLn2;
export const addrLn3 = (state) => state.user.details.addrLn3;
export const town = (state) => state.user.details.town;
export const city = (state) => state.user.details.city;
export const county = (state) => state.user.details.county;
export const country = (state) => state.user.details.country;
export const postCode = (state) => state.user.details.postCode;
export const tel = (state) => state.user.details.tel;
export const emailOpt = (state) => state.user.details.emailOpt;
export const smsOpt = (state) => state.user.details.smsOpt;
export const postOpt = (state) => state.user.details.postOpt;
export const thirdOpt = (state) => state.user.details.thirdOpt;
export const dobDay = (state) => state.user.details.dobDay;
export const dobMnth = (state) => state.user.details.dobMnth;
export const dobYr = (state) => state.user.details.dobYr;

export const bookings = (state) => state.user.bookings;
export const details = (state) => state.user.details;

export const [changePasswordPending, changePasswordFailed, changePasswordError] = getRequestStatus(CHANGE_PASSWORD);

export const [createUserPending, createUserFailed, createUserError] = getRequestStatus(CREATE_USER);

export const getBookingsLoaded = (state) => typeof bookings(state) !== 'undefined';
export const [getBookingsPending, getBookingsFailed, getBookingsError] = getRequestStatus(GET_BOOKINGS);

export const [loggingIn, loginFailed, loginError] = getRequestStatus(LOGIN);

export const [loggingOut, logoutFailed, logoutError] = getRequestStatus(LOGOUT);

export const [requestPasswordResetPending, requestPasswordResetFailed, requestPasswordResetError] = getRequestStatus(
    REQUEST_PASSWORD_RESET,
);

export const [updateUserPending, updateUserFailed, updateUserError] = getRequestStatus(UPDATE_USER);
