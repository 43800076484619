import React from 'react';
import { Card } from 'react-bootstrap';

function ChannelExtra(props) {
    let title = '',
        content = '';
    switch (props.channel) {
        case 'boats':
            title = 'New to Boating?';
            content =
                '<p>If you&apos;ve never been on a boating holiday before, the concept is simple. We provide you with a boat via our partners at Le Boat, which will be your home and transport for the duration of your holiday. Pick it up from one of the many bases, situated along some of the most beautiful waterways in Europe and Canada, and the rest is entirely up to you. Go where you want; do as you please. The only requirement is that you return it back to the base on time. The boats have been designed to make them easy to use, with a steering wheel for left and right and a forward and reverse throttle! No previous boating experience or a licence is required. Learn more now on our <a href="https://www.breakfreeholidays.co.uk/blog/new-to-boating-holidays/" target="_blank" rel="noopener noreferrer">Ultimate Guide to Boating for Beginners</a>.</p><iframe src="https://www.youtube.com/embed/G8eVAkiN6tU?byline=0&amp;title=0&amp;transparent=0&rel=0" title="Video" class="video-iframe" tabindex="-1" style="width: 100%; display: inline-block; margin-top: 1rem; border:0"></iframe>';
            break;
        default:
            title = '';
            content = '';
    }
    return (
        <Card>
            <Card.Header>
                <h2 className="m-0">{title}</h2>
            </Card.Header>
            <Card.Body className="p-3">
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </Card.Body>
        </Card>
    );
}

export default ChannelExtra;
