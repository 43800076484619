import React from 'react';
import PropTypes from 'prop-types';

import Review from './Review';
import t from '../../../../text';
import { Card } from 'react-bootstrap';

function Reviews({ parkDetails, reviews }) {
    let renderedReviews = <p className="p-3">{t('park.reviews.noReviews')}</p>;
    if (reviews?.length) {
        renderedReviews = reviews.map((rev, i) => (
            <div key={i}>
                <Review
                    name={rev.name ? rev.name : t('park.reviews.anonymous')}
                    date={new Date(rev.time).toLocaleDateString('en-GB', {
                        year: 'numeric',
                        month: 'long',
                    })}
                    review={rev.body}
                    rating={rev.score}
                    bubbles={rev.bubbles}
                />
                <hr />
            </div>
        ));
    }

    return (
        <Card>
            <Card.Header>
                <h2 className="m-0">{parkDetails.name}</h2>
            </Card.Header>
            <Card.Body className="p-3">
                {renderedReviews}
            </Card.Body>
        </Card>
    );
}

Reviews.propTypes = {
    parkDetails: PropTypes.object,
    parkId: PropTypes.string,
    reviews: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

Reviews.defaultProps = {
    parkDetails: {},
    reviews: {},
};

export default Reviews;
