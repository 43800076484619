import { createSelector } from 'reselect';

import { CREATE_BOOKING, GET_DEPOSIT } from './action-types';
import { getRequestStatus } from '../../requests/selectors';
import { operators } from '../../data/operators/selectors';
import { regions } from '../../data/regions/selectors';
import { parkDetails } from '../../data/park/details/selectors';
import { extraData, unitTypes } from '../../data/unitTypes/selectors';

import { getOperatorById } from '../../data/operators/utils';
import { getRegionById } from '../../data/regions/utils';
import { getParkDetailsById } from '../../data/park/details/utils';
import { getAllUnitTypeDataById } from '../../data/unitTypes/utils';

export const pendingParams = (state) => state.booking.booking.pendingParams;
export const type = (state) => state.booking.booking.type;
export const channelId = (state) => state.booking.booking.channelId;
export const parkId = (state) => state.booking.booking.parkId;
export const unitId = (state) => state.booking.booking.unitId;
export const unitTypeId = (state) => state.booking.booking.unitTypeId;
export const unitPrice = (state) => state.booking.booking.unitPrice; // new bookings only!
export const totalCost = (state) => state.booking.booking.totalCost; // existing bookings only!
export const amountPaid = (state) => state.booking.booking.amountPaid; // existing bookings only!
export const discountApplied = (state) => state.booking.booking.discountApplied;
export const discountCode = (state) => state.booking.booking.discountCode;
export const discountValue = (state) => state.booking.booking.discountValue;
export const arrivalDate = (state) => state.booking.booking.arrivalDate;
export const duration = (state) => state.booking.booking.duration;
export const bookingId = (state) => state.booking.booking.bookingId;
export const deposit = (state) => state.booking.booking.deposit;
export const depositText = (state) => state.booking.booking.depositText;
export const dueDate = (state) => state.booking.booking.dueDate;
export const confirmationId = (state) => state.booking.booking.confirmationId;

export const [bookingIsFetching, bookingHasError, bookingErrorData] = getRequestStatus(CREATE_BOOKING);
export const bookingIsLoaded = (state) => typeof bookingId(state) !== 'undefined';

export const [depositIsFetching, depositHasError, depositErrorData] = getRequestStatus(GET_DEPOSIT);
export const depositIsLoaded = (state) => typeof deposit(state) !== 'undefined';

export const stepsCompleted = (state) => state.booking.booking.stepsCompleted;

export const park = createSelector(parkDetails, parkId, (_parkDetails, _parkId) => {
    return getParkDetailsById(_parkDetails, _parkId);
});

export const region = createSelector(regions, park, (_regions, _park) => {
    return getRegionById(_regions, _park.regn);
});

export const operator = createSelector(operators, park, (_operators, _park) => {
    return _park ? getOperatorById(_operators, _park.opId) : undefined;
});

export const unit = createSelector(extraData, unitTypes, unitTypeId, (_extraData, _unitTypes, _unitTypeId) => {
    return getAllUnitTypeDataById(_extraData, _unitTypes, _unitTypeId);
});

export const unitPriceNum = createSelector(unitPrice, (_unitPrice) => {
    return typeof _unitPrice !== 'undefined' ? parseFloat(_unitPrice) : undefined;
});

export const depositAvailable = (state) => typeof deposit(state) === 'number';
