import React, { useEffect, useState } from 'react';

import CardPanel from '../../../components/CardPanel';
import ExtrasForm from '../../../components/Forms/Booking/ExtrasForm/ExtrasForm';
import WithHolidaySummary from '../../../components/Booking/WithHolidaySummary';
import WithLoadingStates from '../../../components/Booking/WithLoadingStates';

import { arrayToObject } from '../../../utils/utils';
import { useExtrasFormActions, useExtrasFormState } from '../../../components/Forms/Booking/ExtrasForm/useExtrasForm';
import t from '../../../../text';
import useNextStep from '../../../components/Booking/useNextStep';
import DiscountCode from '../../../components/Booking/DiscountCode';
import { storeDiscountValue } from '../../../redux/booking/booking/actions';
import { useDispatch, useSelector } from 'react-redux';
import { discountApplied } from '../../../redux/booking/booking/selectors';

export const Extras = ({ history, bookingId, stepCompleted }) => {
    const [showDiscountBox, setShowDiscountBox] = useState(false);
    const { getExtras, setExtras } = useExtrasFormActions();
    const {
        values,
        onChange,
        isFetching,
        isSaving,
        hasErrorFetching,
        hasErrorSaving,
        errorDataSaving,
    } = useExtrasFormState();
    const nextStep = useNextStep();

    const loadExtras = () => {
        //setExtras(bookingId, arrayToObject(values, 'code', 'value')); // hacky reset extras errors in state.request
        getExtras(bookingId).request.then((resp) => {
            if (resp && resp?.payload.response?.data?.data?.showDiscountBox === 'true') setShowDiscountBox(true);
        });
    };
    const dispatch = useDispatch();
    const _discountApplied = useSelector(discountApplied);

    const handleNext = () => {
        setExtras(bookingId, arrayToObject(values, 'code', 'value'))
            .request.then((resp) => {
                if (resp.payload.response.data.data !== undefined) {
                    let dV = resp.payload.response.data.data.discountValue || 0;
                    if (!_discountApplied) storeDiscountValue(dispatch, dV);
                }
                stepCompleted();
                history.push(`/booking/${nextStep}`);
            })
            .catch(() => {
                // Do nothing upon error, we'll react to error flags in redux state
            });
    };
    useEffect(loadExtras, [bookingId]); // Reload extras on mount / if the booking ID changes

    return (
        <WithHolidaySummary
            nextButtonText="Continue to booking"
            onNext={handleNext}
            disableNext={isFetching}
            isSaving={isSaving}
        >
            <WithLoadingStates
                isExtras
                theExtras={values}
                errorResponse={errorDataSaving}
                hasErrorFetching={hasErrorFetching}
                hasErrorSaving={hasErrorSaving}
                isFetching={isFetching}
                isSaving={isSaving}
                loadingErrorMessage={t('booking.extras.errorLoading')}
                onRetry={handleNext}
                savingErrorMessage={t('booking.extras.errorSaving')}
            >
                {showDiscountBox && <DiscountCode errorResponse={errorDataSaving} disabled={_discountApplied} />}
                {values.length > 0 && (
                    <CardPanel title="Book Your Extras">
                        <ExtrasForm extras={values} selectExtra={onChange} loading={isFetching} />
                    </CardPanel>
                )}
            </WithLoadingStates>
        </WithHolidaySummary>
    );
};

export default Extras;
