import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

import CardPanel from '../../../components/CardPanel';
import WithHolidaySummary from '../../../components/Booking/WithHolidaySummary';
import WithLoadingStates from '../../../components/Booking/WithLoadingStates';
import useNextStep from '../../../components/Booking/useNextStep';

import PartyDetailsForm from '../../../components/Forms/Booking/PartyDetailsForm/PartyDetailsForm';
import {
    usePartyDetailsFormState,
    usePartyDetailsFormActions,
} from '../../../components/Forms/Booking/PartyDetailsForm/usePartyDetailsForm';

import { config } from '../../../../config';
import t from '../../../../text';

export const Party = ({ history, stepCompleted, onClose, onSubmit, show, useDob, useModal }) => {
    const { selectAreYouGoing, setPartyDetails } = usePartyDetailsFormActions();
    const party = usePartyDetailsFormState();
    const nextStep = useNextStep();

    const removeFields = (values, partySize, field) => {
        const output = { ...values };
        let i = 0;

        for (i; i <= partySize; i++) {
            delete output[`${field}${i}`];
        }

        return output;
    };

    const handleNext = () => {
        party.validate();
        if (party.isValid()) {
            const size = party.adults + party.children + party.under5s;
            let values;

            if (onSubmit) {
                // If custom onSubmit function (modal will likely have this), call that
                values = removeFields(party.values, size, 'pa');
                onSubmit(party.values);
            } else {
                values = removeFields(party.values, size, 'dob');
                setPartyDetails(party.bookingId, size, values)
                    .request.then(() => {
                        stepCompleted();
                        history.push(`/booking/${nextStep}`);
                    })
                    .catch(() => {
                        // Do nothing upon error, we'll react to error flags in redux state
                    });
            }
        }
    };

    const nextButtonTerms = t(
        'booking.booking.confirmTerms',
        config['urls.termsAndConditions'],
        config['urls.privacyPolicy'],
    );

    const partyForm = (
        <PartyDetailsForm
            adults={party.adults}
            // eslint-disable-next-line react/no-children-prop
            children={party.children}
            under5s={party.under5s}
            areYouGoing={party.loggedIn ? party.areYouGoing : 'N'}
            values={party.values}
            errors={party.errors}
            onChangePartyDetail={party.onChange}
            onSelectAreYouGoing={(key, value) => selectAreYouGoing(key, value, party.user)}
            showAreYouGoing={party.loggedIn}
            dob={useDob}
        />
    );

    return useModal ? (
        <Modal show={show} onHide={onClose} centered size="lg">
            <Modal.Header closeButton className="bg-lighter">
                <Modal.Title>
                    <strong>{t('booking.party.bookYourHoliday')}</strong>
                    <div className="h5">{t('booking.booking.stepXofY', 1, 3)}</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h2 className="mb-3 h4">{t('booking.party.yourPartyDetails')}</h2>
                {partyForm}
                <div className="text-right mt-4">
                    <Button variant="primary" onClick={handleNext}>
                        {t('booking.booking.continueToStepX', 2)}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    ) : (
        <WithHolidaySummary
            nextButtonTerms={nextButtonTerms}
            nextButtonText={'Confirm your booking'}
            onNext={handleNext}
            isSaving={party.partyIsSaving}
        >
            <WithLoadingStates
                errorResponse={party.partySaveError}
                hasErrorSaving={party.partyHasSaveError}
                isSaving={party.partyIsSaving}
                loadingErrorMessage={t('booking.party.errorLoading')}
                savingErrorMessage={t('booking.party.errorSaving')}
            >
                <CardPanel className="overflow-visible" title="Your Party Details">
                    {partyForm}
                </CardPanel>
            </WithLoadingStates>
        </WithHolidaySummary>
    );
};

Party.defaultProps = {
    show: false,
    useModal: false,
};

Party.propTypes = {
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    show: PropTypes.bool,
    useDob: PropTypes.bool,
    useModal: PropTypes.bool,
};

export default Party;
