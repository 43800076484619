import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';

import AccommodationModal from './AccommodationModal';
import t from '../../../../text';
import { config } from '../../../../config';
import { Alert } from 'react-bootstrap';
import Icon from '../../Icon';
function AccommodationListing({ holiday, onBook }) {
    const [show, setShow] = useState(false);

    const backgroundImage = holiday.image;

    let unitFeatures = [];
    if (Array.isArray(holiday.features)) {
        holiday.features.forEach((feature) => {
            unitFeatures.push(
                <div key={feature}>
                    <i className="icon-check text-secondary mr-1"></i>
                    {feature}
                </div>,
            );
        });
    }

    let unitDescription = false;
    if (holiday.desc) {
        let descWordArray = holiday.desc.replace(/\s\s+/g, ' ').split(' ');
        let wordsLength = descWordArray.length;
        unitDescription = descWordArray
            .splice(0, 32)
            .join(' ')
            .replace(/[^a-zA-Z0-9]*$/g, '');
        unitDescription += wordsLength > 32 ? '...' : '.';
    }

    return (
        <Row noGutters className="content-box accommodation-listing">
            <Col
                md={6}
                lg={5}
                style={{ backgroundImage: `url('${backgroundImage}')` }}
                className={`accommodation-listing-image ${backgroundImage ? '' : 'd-none'}`}
            >
                {+holiday.unitQuantity < 5 && (
                    <span className="accommodation-listing-remaining bg-secondary text-white text-sm p-2">
                        {t('park.accommodation.onlyXLeft', holiday.unitQuantity)}
                    </span>
                )}
            </Col>

            <Col>
                {!backgroundImage && +holiday.unitQuantity < 5 && (
                    <span className="accommodation-listing-remaining bg-secondary text-white text-sm p-2">
                        {t('park.accommodation.onlyXLeft', holiday.unitQuantity)}
                    </span>
                )}
                <div className="p-3">
                    <h3 className="mb-3">{holiday.unitDesc}</h3>
                    <p>
                        <i className={`icon-bed text-${config['global.textHighlight']} h4`}></i>{' '}
                        {t('park.accommodation.sleepsX', holiday.unitMaxOccupancy)}
                        {holiday.unitDisabled === 'Y' && (
                            <span>
                                <i
                                    className={`icon-disabled-access h4 text-${config['global.textHighlight']} ml-3`}
                                ></i>{' '}
                                {t('park.accommodation.disabledAccess')}
                            </span>
                        )}
                        {holiday.unitAdultsOnly === 'Y' && (
                            <Alert variant="warning" className="p-2 mt-3 text-sm">
                                <Icon icon="attention-alt" theme="secondary" size="h4" className="mr-2" />
                                Please Note: This unit is adults only.
                            </Alert>
                        )}
                    </p>

                    {unitDescription && <p>{unitDescription}</p>}

                    {holiday.desc && (
                        <>
                            <p>
                                <Button variant="light" size="sm" className="border" onClick={() => setShow(true)}>
                                    {t('buttons.readMore')}
                                </Button>
                            </p>
                            <AccommodationModal
                                holiday={holiday}
                                unitFeatures={unitFeatures}
                                onHide={() => setShow(false)}
                                show={show}
                            />
                        </>
                    )}
                </div>

                <Row
                    noGutters
                    className="d-flex align-self-end align-items-center bg-lighter border-top border-light p-3"
                >
                    <Col xs={6} lg={3} className="mb-3 mb-lg-0">
                        <h3 className="m-0 h2">
                            <strong className={`text-${config['global.textHighlight']}`}>
                                &pound;{holiday.unitPrice}
                            </strong>
                        </h3>
                    </Col>
                    <Col xs={6} lg={3} className="mb-3 mb-lg-0">
                        {+holiday.unitPriceSave ? (
                            <p className="text-uppercase text-success font-weight-bold saving mb-0">
                                <i className="icon-check h5 text-success"></i>{' '}
                                {t('park.accommodation.saveX', holiday.unitPriceSave)}
                            </p>
                        ) : null}
                    </Col>
                    <Col lg={6}>
                        <Button className="btn btn-primary btn-block py-2 mt-3 mt-md-0" onClick={onBook}>
                            {t('buttons.book')}
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

AccommodationListing.propTypes = {
    holiday: PropTypes.object,
    onBook: PropTypes.func,
};

AccommodationListing.defaultProps = {};

export default AccommodationListing;
