import React, { Children, cloneElement, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { loggedIn } from '../../redux/user/selectors';
import LoginModal from '../Login/LoginModal';

export const WithLoginViaModal = (props) => {
    const _loggedIn = useSelector(loggedIn);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [data, setData] = useState({});

    const handleClose = () => {
        setShowLoginModal(false);
        if (_loggedIn && props.onNext) {
            props.onNext(data);
        }
    };

    const onNext = useCallback(
        (newData) => {
            if (!_loggedIn) {
                setData(newData);
                setShowLoginModal(true);
            } else {
                if (props.onNext) props.onNext(newData);
            }
        },
        [_loggedIn, props],
    );

    return (
        <>
            <LoginModal onClose={handleClose} redirectOnLogin={!props.onNext} show={showLoginModal} />
            {Children.map(props.children || [], (child) => {
                return cloneElement(child, {
                    ...props,
                    ...child.props,
                    onNext,
                });
            })}
        </>
    );
};

WithLoginViaModal.propTypes = {
    onNext: PropTypes.func,
};

export default WithLoginViaModal;
