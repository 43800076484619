import React, { useState, useEffect } from 'react';
import { Accordion, Card, Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import PageTitle from '../../components/PageTitle';
import ScrollToTop from '../../components/ScrollToTop';
import { config, urls } from '../../../config';

function FAQ() {
    const [termData, setTermData] = useState([]);
    const [active, setActive] = useState('0');

    useEffect(() => {
        const controller = new AbortController();
        fetch(
            `${urls.cms}/faq/?_fields=title.rendered,content.rendered,acf.order&status=publish`,
            { signal: controller.signal },
        )
            .then((response) => response.json())
            .then((data) => {
                data.sort((a, b) => {
                    return a.acf.order - b.acf.order;
                });
                setTermData(data);
            })
            .catch((e) => {
                console.error('Could not fetch the content.', e);
            });
        return () => {
            controller.abort();
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>Frequently Asked Questions (FAQ)</title>
                <link rel="canonical" href={`${config['global.siteUrl']}/faq`}/>
            </Helmet>
            <ScrollToTop />

            <PageTitle title="Frequently Asked Questions" />

            <Container className="my-5">
                <p>
                    The list below should answer most of the commonly raised questions. If you cannot find the
                    information you’re looking for here, please check out the Terms and Conditions, which may answer
                    your question. If you still need further help, click on the Contact Us button at the bottom of this
                    page. Please note: Separate terms and conditions apply to our promotional offers.
                </p>
                <Accordion activeKey={active}>
                    {termData.map((term, i) => {
                        return (
                            <Card key={i} className={active === '' + i ? 'active' : ''} data-order={term.acf.order}>
                                <Accordion.Toggle as={Card.Header} eventKey={'' + i} onClick={() => setActive('' + i)}>
                                    {term.title.rendered}
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={'' + i}>
                                    <Card.Body
                                        dangerouslySetInnerHTML={{ __html: term.content.rendered }}
                                        className="pt-3 px-3"
                                    ></Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        );
                    })}
                </Accordion>
            </Container>
        </>
    );
}

export default FAQ;
