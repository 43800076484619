import React from 'react';
import PropTypes from 'prop-types';
import t from '../../../../text';
import { config } from '../../../../config';
import { Dropdown } from 'react-bootstrap';

function ParkInformationMenu(props) {
    const itemClasses = ['cursor-pointer', 'd-flex', 'justify-content-between', 'text-lg', 'p-3'];
    const doChannelExtra = ['boats'];
    const availableTabs = [
        {
            name: props.channel === 'ukcottages' ? 'Property Information' : `${t('park.info.facilities')}`,
            key: 'facilities',
        },
    ];

    if (config['global.feefoReviews'] && config['parks.showReviews']) {
        availableTabs.push({ name: t('park.info.reviews'), key: 'reviews' });
    }
    if (props.showAccommodation)
        availableTabs.unshift({
            name: props.channel === 'boats' ? 'Boats' : 'Accommodation',
            key: 'accommodation',
        });
    if (props.showAltAccommodation)
        availableTabs.unshift({
            name: props.channel === 'boats' ? 'Boats' : 'Accommodation',
            key: 'altaccommodation',
        });
    if (props.showImportantInfo) {
        availableTabs.push({ name: t('park.info.importantInfo'), key: 'importantInfo' });
    }

    if (props.showLocation) availableTabs.push({ name: 'Location', key: 'location' });
    if (props.videoCount > 0) availableTabs.push({ name: `Video${props.videoCount > 1 ? 's' : ''}`, key: 'videos' });
    if (props.showThingsToDo) availableTabs.push({ name: 'Things to Do', key: 'ttd' });
    if (props.showQA) availableTabs.push({ name: 'Q & A', key: 'qa' });
    if (doChannelExtra.includes(props.channel)) availableTabs.push({ name: 'New to Boating', key: 'channelExtra' });

    return (
        <>
            <ul className="vertical-tab-menu d-none d-md-block">
                {availableTabs.map((item) => (
                    <li
                        key={item.key}
                        onClick={() => props.clicked(item.key)}
                        className={
                            props.active === item.key ? itemClasses.concat('active').join(' ') : itemClasses.join(' ')
                        }
                    >
                        {item.name}
                        {props.active === item.key && <i className="text-lg icon-right"></i>}
                    </li>
                ))}
            </ul>

            <Dropdown className="vertical-tab-menu-sm mb-5 d-block d-md-none">
                <Dropdown.Toggle as="button" type="button" className="toggle-popup is-dropdown border" role="button">
                    <span className="">{availableTabs.filter((obj) => obj.key === props.active)[0].name}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {availableTabs.map((item) => (
                        <Dropdown.Item key={item.key} onClick={() => props.clicked(item.key)}>
                            {item.name}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}

ParkInformationMenu.propTypes = {
    active: PropTypes.string,
    clicked: PropTypes.func,
    showAccommodation: PropTypes.bool,
    showImportantInfo: PropTypes.bool,
};

export default ParkInformationMenu;
