import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import Home from './pages/Home/container';
import { contentRoutes } from '../config';
import ContactUs from './pages/ContactUs/ContactUs';
import NotFound from './pages/404';
import ChangePassword from './pages/ChangePassword/ChangePassword';
import Login from './pages/Login/container';
import MyAccount from './pages/MyAccount/MyAccount';
import SearchResults from './pages/SearchResults/SearchResults';
import ParkPage from './pages/HolidayPark/ParkPage';
import ParkFromSearch from './pages/ParkFromSearch';
import Booking from './pages/Booking/Booking';
import FAQ from './pages/Content/FAQ';
import FetchContent from './pages/Content/FetchContent';
import TermsConditions from './pages/Content/TermsConditions';
import TravelGuide from './pages/Content/TravelGuide';
import Parks from './pages/Browse/Parks';
import OperatorRegions from './pages/Browse/OperatorRegions';
import Operators from './pages/Browse/Operators';
import Regions from './pages/Browse/Regions';
import TravelGuideHome from './pages/TravelGuideHome/TravelGuideHome';
import Reset from './pages/Reset';

export const Routes = () => (
    <Switch>
        <Route path="/" exact component={Home} />
        {/* Search & Booking */}
        <Route path="/results" component={SearchResults} />
        <Route path="/boat/:channelId/:regionId/:parkId/:slug?" component={ParkPage} />
        <Route path="/boat-result/:channelId/:regionId/:parkId/:slug?" component={ParkPage} />
        <Route path="/park/:channelId/:regionId/:parkId/:slug?" component={ParkPage} />
        <Route path="/park-result/:channelId/:regionId/:parkId/:slug?" component={ParkPage} />
        <Route path="/booking" component={Booking} exact />
        <Route path="/booking/:step" component={Booking} />
        <Route path="/park-from-search/:channelId/:regionId/:parkId" component={ParkFromSearch} />
        <Route path="/deal-of-the-week/:channelId/:regionId/:parkId" component={ParkFromSearch} />
        {/* Browsing */}
        <Route path="/brands/:channelCode" component={Operators} />
        <Route path="/brands" component={Operators} />
        <Route path="/channel/:channelCode" strict component={Regions} />
        <Route path="/region/:channelCode" strict component={Regions} />
        <Route path="/dog-friendly/:channelCode" strict component={Regions} />
        <Route path="/:operatorId/region/:channelCode" strict component={OperatorRegions} />
        <Route path="/parks/:channelId/:regionId/:slug?" component={Parks} />
        <Route path="/holiday-parks/:channelId/:regionId/:slug?" component={Parks} />
        <Route path="/area/:channelId/:regionId/:townId/:slug?" component={Parks} /> {/* <-- Town pages */}
        <Route path="/boating-holidays/:channelId/:regionId/:slug?" component={Parks} />
        <Redirect from="/rentals" to="/region/ukcottages" />
        <Redirect exact from="/dog-friendly/uk" to="/dog-friendly/ukcaravan" />
        {/* User */}
        <Route path="/change-password" component={ChangePassword} />
        <Route path="/login" component={Login} />
        <Route path="/my-account" component={MyAccount} />
        {/* Content */}
        <Route path="/inspiration" component={TravelGuideHome} />
        <Route path="/p/:contentType/:contentId" component={FetchContent} />
        <Route path="/a/:contentId/:keywords">
            <FetchContent contentData="age" />
        </Route>
        <Route path="/b/:contentId/:keywords">
            <FetchContent contentData="ost" />
        </Route>
        <Redirect from="/a/:contentId/:keywords" to="/a/:contentId" />
        <Route path="/terms" component={TermsConditions} />
        <Route path="/faq" component={FAQ} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/travel-guide/:regionName/:regionId" component={TravelGuide} />
        {/* CMS content */}
        {contentRoutes.map((route) => (
            <Route key={route.path} path={route.path} component={() => <FetchContent post={route.url} />} />
        ))}
        {/* Dev tools */}
        <Route path="/reset" component={Reset} />
        <Route path="/404" component={NotFound} />
        <Redirect to="/404" />
    </Switch>
);

export default Routes;
