import { connect } from 'react-redux';
import Details from './Details';

import { stepCompleted } from '../../../redux/booking/booking/actions';
import { updateUser } from '../../../redux/user/actions';

import { BOOKING_STEPS } from '../../../Constants';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    stepCompleted: () => stepCompleted(dispatch, BOOKING_STEPS.DETAILS),
    updateUser: (detailsData, offersData) => updateUser(dispatch, { ...detailsData, ...offersData }),
});

export const DetailsConnected = connect(mapStateToProps, mapDispatchToProps)(Details);
export default DetailsConnected;
