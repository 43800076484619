import React from 'react';
import PropTypes from 'prop-types';
import { Nav, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import Icon from '../Icon';
import t from '../../../text';

export const HeaderNavLoginButtons = ({ loggedIn, onLogout, onShowLogin, showBackground }) => (
    <Nav>
        {!loggedIn ? (
            <Button
                type="button"
                size="sm"
                className={showBackground ? 'd-block' : 'd-none'}
                variant="darker"
                onClick={onShowLogin}
            >
                <Icon icon="sign-in" /> {t('header.loginSignUp')}
            </Button>
        ) : (
            <>
                <NavLink
                    to="/my-account"
                    className={`btn btn-darker btn-sm mr-md-3 mb-3 mb-md-0 ${showBackground ? 'd-block' : 'd-none'}`}
                >
                    <Icon icon="user" /> {t('header.myAccount')}
                </NavLink>
                <Button
                    type="button"
                    size="sm"
                    className={showBackground ? 'd-block' : 'd-none'}
                    variant="darker"
                    onClick={onLogout}
                >
                    <Icon icon="previous" /> {t('header.logout')}
                </Button>
            </>
        )}
    </Nav>
);

HeaderNavLoginButtons.propTypes = {
    loggedIn: PropTypes.bool,
    onLogout: PropTypes.func,
    onShowLogin: PropTypes.func,
    showBackground: PropTypes.bool,
};

export default HeaderNavLoginButtons;
