import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AccommodationListing from './AccommodationListing';

import { getDayNameFromDate, getDayAndMonthNameFromDate } from '../../../utils/date';
import t from '../../../../text';
import { Form, FormControl } from 'react-bootstrap';

export const Accommodation = (props) => {
    const sortOptions = [
        {
            label: 'Price Low to High',
            value: 'asc',
        },
        {
            label: 'Price High to Low',
            value: 'desc',
        },
    ];
    const [sortOption, setSortOption] = useState(sortOptions[0]);

    const onChange = (val) => {
        const correctOption = sortOptions.find((option) => option.value === val);
        setSortOption(correctOption);
    };

    const sortResults = (accomodations) => {
        const isItAscending = sortOption.value === 'asc';
        return accomodations.sort((a, b) => (isItAscending ? a.unitPrice - b.unitPrice : b.unitPrice - a.unitPrice));
    };

    return props.result ? (
        <>
            <div className="content-box bg-lighter d-flex flex-column flex-md-row justify-content-between align-items-center">
                <p className="h3 p-3 m-0">
                    {t(
                        'park.accommodation.summary',
                        props.result.units.length,
                        `${getDayNameFromDate(props.result.date)} ${getDayAndMonthNameFromDate(props.result.date)}`,
                        props.result.nights,
                    )}
                </p>
                <Form.Group className="px-3 pb-3 pt-0 pt-md-3 m-0">
                    <FormControl as="select" className="m-0 w-auto" onChange={(e) => onChange(e.target.value)}>
                        {sortOptions.map((opt) => (
                            <option key={`${opt.value}`} value={`${opt.value}`}>
                                {opt.label}
                            </option>
                        ))}
                    </FormControl>
                </Form.Group>
            </div>
            {sortResults(props.result.units).map((unit) => (
                <AccommodationListing
                    key={unit.unitId}
                    holiday={unit}
                    parkDetails={props.parkDetails}
                    onBook={() => props.onBook(unit)}
                />
            ))}
        </>
    ) : (
        <div className="content-box p-4">
            <h2 className="mb-4">{t('park.accommodation.accommodation')}</h2>
            {t('park.accommodation.noResults')}
        </div>
    );
};

Accommodation.propTypes = {
    onBook: PropTypes.func,
    parkDetails: PropTypes.object,
    result: PropTypes.object,
};

Accommodation.defaultProps = {};

export default Accommodation;
