import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Dropdown } from 'react-bootstrap';
import InputNumber from '../FormElements/InputNumber';
import { shouldItPluralize } from '../../utils/utils';
import t from '../../../text';
import Icon from '../Icon';

export const GuestsSelect = (props) => {
    const guestTotal = props.adults + props.children + props.under5s;

    const handleGuestUpdate = (num, param, setterFunc) => {
        let total = 0;

        switch (param) {
            case 'adults':
                total = props.children + props.under5s + num;
                break;
            case 'children':
                total = props.adults + props.under5s + num;
                break;
            case 'under5s':
                total = props.adults + props.children + num;
                break;
            default:
                total = 0;
        }

        if (total <= props.maxGuests) setterFunc(num);
    };

    return (
        <Form.Group>
            <Form.Label className={`text-${props.textStyle}`}>
                <span>{t('search.yourPartySize', props.maxGuests)}</span>
            </Form.Label>
            <Dropdown>
                <Dropdown.Toggle
                    as="button"
                    type="button"
                    className="toggle-popup is-dropdown"
                    role="button"
                    tabIndex="0"
                    disabled={props.disabled}
                >
                    <span>{guestTotal === 0 ? 'Add guests' : `${guestTotal} ${shouldItPluralize('guest', guestTotal)}`}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="a-popup">
                    <Row className="p-3 d-flex align-items-center">
                        <Col xs={6}>
                            <p className="m-0">{t('search.adults')}</p>
                        </Col>
                        <Col xs={6}>
                            <InputNumber
                                id={props.id + '-adults'}
                                value={
                                    props.selectedChannel === 'boats' && props.adults < 2
                                        ? handleGuestUpdate(2, 'adults', props.onAdultsChange)
                                        : props.adults
                                }
                                min={props.selectedChannel === 'boats' ? 2 : 1}
                                max={props.maxGuests - (props.children + props.under5s)}
                                onChange={(num) => handleGuestUpdate(num, 'adults', props.onAdultsChange)}
                            />
                        </Col>
                        {props.selectedChannel === 'boats' && (
                            <Col>
                                <Icon icon="attention" size="text-sm" className="mr-1" />
                                <small>Min. 2 adults for Boating holidays.</small>
                            </Col>
                        )}
                    </Row>
                    <Dropdown.Divider />
                    <Row className="p-3 d-flex align-items-center">
                        <Col xs={6}>
                            <p className="m-0">{t('search.children')}</p>
                        </Col>
                        <Col xs={6}>
                            <InputNumber
                                id={props.id + '-children'}
                                value={props.children}
                                max={props.maxGuests - (props.adults + props.under5s)}
                                onChange={(num) => handleGuestUpdate(num, 'children', props.onChildrenChange)}
                            />
                        </Col>
                    </Row>
                    <Dropdown.Divider />
                    <Row className="p-3 d-flex align-items-center">
                        <Col xs={6}>
                            <p className="m-0">{t('search.infants')}</p>
                        </Col>
                        <Col xs={6}>
                            <InputNumber
                                id={props.id + '-under5s'}
                                value={props.under5s}
                                max={props.maxGuests - (props.adults + props.children)}
                                onChange={(num) => handleGuestUpdate(num, 'under5s', props.onUnder5sChange)}
                            />
                        </Col>
                    </Row>
                </Dropdown.Menu>
            </Dropdown>
        </Form.Group>
    );
};

GuestsSelect.defaultProps = {
    maxGuests: 10,
};

GuestsSelect.propTypes = {
    id: PropTypes.string,
    textStyle: PropTypes.string,
    label: PropTypes.string,
    adults: PropTypes.number,
    children: PropTypes.number,
    under5s: PropTypes.number,
    onAdultsChange: PropTypes.func,
    onChildrenChange: PropTypes.func,
    onUnder5sChange: PropTypes.func,
    maxGuests: PropTypes.number,
    disabled: PropTypes.bool,
    selectedChannel: PropTypes.string,
};

export default GuestsSelect;
