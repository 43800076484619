import React from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import ContactPreferencesInputs from '../ContactPreferencesForm/ContactPreferencesInputs';
import DateInput from '../../../FormElements/DateInput';
import RequestErrors from '../../../RequestErrors';
import SelectInput from '../../../FormElements/SelectInput';
import TextInput from '../../../FormElements/TextInput';

import countries from '../../../../Data/Countries.json';
import titles from '../../../../Data/Titles.json';

import { validateFields as validateContactPrefsFields } from '../ContactPreferencesForm/ContactPreferencesForm';
import { required, email, telephone, password, postcode, dateValidator } from '../../../../utils/validation';
import t from '../../../../../text';

export const validateFields = (values = {}) => {
    const errors = {};
    if (!required(values.title)) {
        errors.title = t('forms.forms.noValueSelected');
    }
    if (!required(values.firstName)) {
        errors.firstName = t('forms.user.blankFirstName');
    }
    if (!required(values.lastName)) {
        errors.lastName = t('forms.user.blankSurname');
    }
    if (!required(values.email) || !email(values.email)) {
        errors.email = t('forms.user.invalidEmail');
    }
    if (!required(values.email)) {
        errors.email = t('forms.user.blankEmail');
    }
    if (!required(values.passwd) || !password(values.passwd)) {
        errors.passwd = t('forms.user.invalidPassword');
    }
    if (!required(values.passwd)) {
        errors.passwd = t('forms.user.blankPassword');
    }
    if (!required(values.tel) || !telephone(values.tel)) {
        errors.tel = t('forms.user.invalidTelephone');
    }
    if (!required(values.dob) || !dateValidator(values.dob)) {
        errors.dob = t('forms.user.blankDob');
    }
    if (!required(values.addrLn1)) {
        errors.addrLn1 = t('forms.address.blankAddress');
    }
    if (!required(values.town)) {
        errors.town = t('forms.address.blankTown');
    }
    if (!required(values.postCode) || !postcode(values.postCode)) {
        errors.postCode = t('forms.address.invalidPostcode');
    }
    if (!required(values.county)) {
        errors.county = t('forms.address.blankCounty');
    }
    if (!required(values.country)) {
        errors.country = t('forms.forms.noValueSelected');
    }

    // Validate contact prefs fields
    Object.assign(errors, validateContactPrefsFields(values));

    return errors;
};

export const isValid = (values = {}) => !Object.keys(validateFields(values)).length;

export const CreateUserForm = (props) => {
    const handleDobChange = (field, value = '') => {
        const dobParts = value.split('/');
        props.onChange({
            [field]: value,
            dobDay: dobParts[0],
            dobMnth: dobParts[1],
            dobYr: dobParts[2],
        });
    };

    return (
        <Form name="detailsForm" noValidate>
            <Form.Row>
                <SelectInput
                    groupClassName="col-12 col-sm-4 col-lg-2"
                    label={t('forms.user.title')}
                    name="title"
                    value={props.values.title || ''}
                    onChange={(e) => props.onChange(e.target.name, e.target.value)}
                    invalid={!!props.errors.title}
                    validationMessage={props.errors.title}
                    attrs={{ disabled: props.busy }}
                >
                    <option value="" disabled></option>
                    {Object.keys(titles).map((key) => (
                        <option key={key} value={key}>
                            {titles[key]}
                        </option>
                    ))}
                </SelectInput>

                <TextInput
                    groupClassName="col-12 col-sm-4 col-lg-5"
                    label={t('forms.user.firstName')}
                    name="firstName"
                    value={props.values.firstName}
                    onChange={(e) => props.onChange(e.target.name, e.target.value)}
                    invalid={!!props.errors.firstName}
                    validationMessage={props.errors.firstName}
                    attrs={{ disabled: props.busy }}
                />

                <TextInput
                    groupClassName="col-12 col-sm-4 col-lg-5"
                    label={t('forms.user.surname')}
                    name="lastName"
                    value={props.values.lastName}
                    onChange={(e) => props.onChange(e.target.name, e.target.value)}
                    invalid={!!props.errors.lastName}
                    validationMessage={props.errors.lastName}
                    attrs={{ disabled: props.busy }}
                />
            </Form.Row>

            <Form.Row>
                <TextInput
                    groupClassName="col"
                    label={t('forms.user.emailAddress')}
                    name="email"
                    type="email"
                    value={props.values.email}
                    onChange={(e) => props.onChange(e.target.name, e.target.value)}
                    invalid={!!props.errors.email}
                    validationMessage={props.errors.email}
                    attrs={{ disabled: props.busy }}
                />
                <TextInput
                    groupClassName="col"
                    label={t('forms.user.password')}
                    name="passwd"
                    type="password"
                    value={props.values.passwd}
                    onChange={(e) => props.onChange(e.target.name, e.target.value)}
                    invalid={!!props.errors.passwd}
                    validationMessage={props.errors.passwd}
                    attrs={{ disabled: props.busy }}
                />
            </Form.Row>

            <Form.Row>
                <TextInput
                    groupClassName="col"
                    label={t('forms.user.telephone')}
                    name="tel"
                    type="tel"
                    value={props.values.tel}
                    onChange={(e) => props.onChange(e.target.name, e.target.value)}
                    invalid={!!props.errors.tel}
                    validationMessage={props.errors.tel}
                    attrs={{ disabled: props.busy }}
                />
            </Form.Row>

            <Form.Row>
                <DateInput
                    groupClassName="col"
                    label={t('forms.user.dob')}
                    name="dob"
                    value={props.values.dob}
                    onChange={handleDobChange}
                    invalid={!!props.errors.dob}
                    validationMessage={props.errors.dob}
                    attrs={{ disabled: props.busy, autoComplete: 'off' }}
                    dob
                />
            </Form.Row>

            <Form.Row>
                <TextInput
                    groupClassName="col"
                    label={t('forms.address.address')}
                    name="addrLn1"
                    value={props.values.addrLn1}
                    onChange={(e) => props.onChange(e.target.name, e.target.value)}
                    invalid={!!props.errors.addrLn1}
                    validationMessage={props.errors.addrLn1}
                    attrs={{ disabled: props.busy }}
                />
            </Form.Row>

            <Form.Row>
                <TextInput
                    groupClassName="col"
                    label={t('forms.address.town')}
                    name="town"
                    value={props.values.town}
                    onChange={(e) => props.onChange(e.target.name, e.target.value)}
                    invalid={!!props.errors.town}
                    validationMessage={props.errors.town}
                    attrs={{ disabled: props.busy }}
                />
                <TextInput
                    groupClassName="col"
                    label={t('forms.address.county')}
                    name="county"
                    value={props.values.county}
                    onChange={(e) => props.onChange(e.target.name, e.target.value)}
                    invalid={!!props.errors.county}
                    validationMessage={props.errors.county}
                    attrs={{ disabled: props.busy }}
                />
            </Form.Row>

            <Form.Row>
                <TextInput
                    groupClassName="col"
                    label={t('forms.address.postcode')}
                    name="postCode"
                    value={props.values.postCode}
                    onChange={(e) => props.onChange(e.target.name, e.target.value)}
                    attrs={{ maxLength: 8, disabled: props.busy }}
                    invalid={!!props.errors.postCode}
                    validationMessage={props.errors.postCode}
                />

                <SelectInput
                    groupClassName="col"
                    label={t('forms.address.country')}
                    name="country"
                    value={props.values.country || ''}
                    onChange={(e) => props.onChange(e.target.name, e.target.value)}
                    invalid={!!props.errors.country}
                    validationMessage={props.errors.country}
                    attrs={{ disabled: props.busy }}
                >
                    <option value="" disabled>
                        {t('forms.address.selectCountry')}
                    </option>
                    {Object.keys(countries).map((key) => (
                        <option key={key} value={key}>
                            {countries[key].label}
                        </option>
                    ))}
                </SelectInput>
            </Form.Row>

            <ContactPreferencesInputs onChange={props.onChange} values={props.values} errors={props.errors} />

            {(props.onBack || props.onSubmit) && (
                <Form.Row noGutters>
                    {props.onBack && (
                        <Col xs={'auto'}>
                            <Button variant="outline-primary" onClick={props.onBack} disabled={props.busy}>
                                {t('buttons.back')}
                            </Button>
                        </Col>
                    )}
                    {props.onSubmit && (
                        <Col>
                            <Button block type="submit" onClick={props.onSubmit} disabled={props.busy}>
                                {props.busy ? t('global.pleaseWait') : t('buttons.register')}
                            </Button>
                        </Col>
                    )}
                </Form.Row>
            )}

            {props.failure && <RequestErrors requestError={props.failure} />}
        </Form>
    );
};

CreateUserForm.defaultProps = {
    busy: false,
    errors: {},
    values: {},
};

CreateUserForm.propTypes = {
    busy: PropTypes.bool,
    errors: PropTypes.object,
    failure: PropTypes.object,
    onBack: PropTypes.func,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    values: PropTypes.object,
};

export default CreateUserForm;
