import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, ButtonGroup } from 'react-bootstrap';

import Icon from './Icon';

export const PageTitle = ({ title, subTitle, viewMode, onChangeViewMode }) => (
    <Container fluid className="bg-lighter border-bottom mb-4 py-4 px-0">
        <Container>
            <Row className="align-items-center">
                <Col className="text-center text-sm-left">
                    <h1 className="m-0">{title}</h1>
                    {subTitle && <p className="m-0">{subTitle}</p>}
                </Col>
                {viewMode && onChangeViewMode && (
                    <Col className="col-12 col-sm-auto text-center mt-3 mt-sm-0">
                        <ButtonGroup size="sm">
                            <button
                                type="button"
                                className={`btn ${viewMode === 'list' ? 'btn-primary' : 'btn-light'} font-size-3`}
                                onClick={() => onChangeViewMode('list')}
                            >
                                <Icon icon="list" className="mr-1 lead" /> List
                            </button>
                            <button
                                type="button"
                                className={`btn ${viewMode === 'grid' ? 'btn-primary' : 'btn-light'} font-size-3`}
                                onClick={() => onChangeViewMode('grid')}
                            >
                                <Icon icon="grid" className="mr-1 lead" /> Grid
                            </button>
                            <button
                                type="button"
                                className={`btn ${viewMode === 'map' ? 'btn-primary' : 'btn-light'} font-size-3`}
                                onClick={() => onChangeViewMode('map')}
                            >
                                <Icon icon="map-marker" className="mr-1 lead" /> Map
                            </button>
                        </ButtonGroup>
                    </Col>
                )}
            </Row>
        </Container>
    </Container>
);

PageTitle.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    viewMode: PropTypes.oneOf(['list', 'grid', 'map']),
    onChangeViewMode: PropTypes.func,
};

PageTitle.defaultProps = {
    title: 'The Page Title has not been set.',
};

export default PageTitle;
