import React from 'react';
import { useSelector } from 'react-redux';

import CardPanel from '../../../components/CardPanel';
import t from '../../../../text';
import WithHolidaySummary from '../../../components/Booking/WithHolidaySummary';
import WithLoadingStates from '../../../components/Booking/WithLoadingStates';
import useNextStep from '../../../components/Booking/useNextStep';

import ContactPreferencesForm from '../../../components/Forms/User/ContactPreferencesForm/ContactPreferencesForm';
import { useContactPreferencesFormState } from '../../../components/Forms/User/ContactPreferencesForm/useContactPreferencesForm';

import PaymentOptionsForm from '../../../components/Forms/Booking/PaymentOptionsForm/PaymentOptionsForm';
import { usePaymentOptionsFormState } from '../../../components/Forms/Booking/PaymentOptionsForm/usePaymentOptionsForm';

import UpdateUserForm from '../../../components/Forms/User/UpdateUserForm/UpdateUserForm';
import { useUpdateUserFormState } from '../../../components/Forms/User/UpdateUserForm/useUpdateUserForm';

import { config } from '../../../../config';
import { channelId } from '../../../redux/booking/booking/selectors';

export const Details = ({ history, stepCompleted, updateUser }) => {
    const _channelId = useSelector(channelId);
    const details = useUpdateUserFormState();
    const contactPreferences = useContactPreferencesFormState();
    const nextStep = useNextStep();
    const payment = usePaymentOptionsFormState();

    const handleNext = () => {
        details.validate();
        if (details.isValid()) {
            updateUser(details.values, contactPreferences.values)
                .request.then(() => {
                    stepCompleted();
                    history.push(`/booking/${nextStep}`);
                })
                .catch(() => {
                    // Do nothing upon error, we'll react to error flags in redux state
                });
        }
    };

    const nextButtonTerms = t(
        'booking.booking.confirmTerms',
        config['urls.termsAndConditions'],
        config['urls.privacyPolicy'],
    );

    return (
        <WithHolidaySummary
            nextButtonTerms={_channelId === 'eurocaravan' ? nextButtonTerms : undefined}
            nextButtonText={_channelId === 'eurocaravan' ? 'Confirm your booking' : 'Continue'}
            onNext={handleNext}
            isSaving={details.updateUserPending}
        >
            <WithLoadingStates
                hasErrorSaving={details.updateUserFailed}
                errorResponse={details.updateUserError}
                isSaving={details.updateUserPending}
                loadingErrorMessage={t('booking.details.errorLoading')}
                savingErrorMessage={t('booking.details.errorSaving')}
            >
                <CardPanel title="Your Details">
                    <UpdateUserForm values={details.values} errors={details.errors} onChange={details.onChange} />
                </CardPanel>
                <CardPanel title="Payment Options">
                    <PaymentOptionsForm
                        totalCostDeposit={payment.totalCostDeposit}
                        totalCostFull={payment.totalCostFull}
                        totalCostRemaining={payment.totalCostRemaining}
                        deposit={payment.deposit}
                        depositAvailable={payment.depositAvailable}
                        depositText={payment.depositText}
                        dueDate={payment.dueDate}
                        selectedOption={payment.values}
                        onChange={payment.onChange}
                    />
                </CardPanel>
                <CardPanel title="Important Information">
                    <ContactPreferencesForm values={contactPreferences.values} onChange={contactPreferences.onChange} />
                </CardPanel>
            </WithLoadingStates>
        </WithHolidaySummary>
    );
};

export default Details;
