import { extras as extrasData } from '../extras/selectors';
import {
    amountPaid,
    deposit,
    depositAvailable,
    totalCost as existingTotalCost,
    type,
    unitPrice,
    discountValue
} from '../booking/selectors';
import { getRequestStatus } from '../../requests/selectors';

import { SET_PARTY_DETAILS } from './action-types';

export const details = (state) => state.booking.form.details;
export const offers = (state) => state.booking.form.offers;
export const party = (state) => state.booking.form.party;

// Extras
export const extras = (state) => state.booking.form.extras;
export const extrasWithValues = (state) => {
    return extrasData(state).map((extraData) => {
        const value = extras(state)[extraData.code] || 0;
        const totalCost = parseFloat(extraData.cost) * value;
        const totalDepcost = parseFloat(extraData.depcost) * value;
        return { ...extraData, value, totalCost, totalDepcost };
    });
};
export const selectedExtrasWithValues = (state) => {
    return extrasWithValues(state).filter((extra) => extra.value > 0);
};
export const selectedExtrasDepositTotal = (state) => {
    return extrasWithValues(state).reduce((total, extra) => {
        return extra.totalDepcost + total;
    }, 0);
};
export const selectedExtrasTotal = (state) => {
    return selectedExtrasWithValues(state).reduce((total, extra) => {
        return extra.totalCost + total;
    }, 0);
};

// Party
export const partyAreYouGoing = (state) => state.booking.form.party.areYouGoing;
export const partyValues = (state) => state.booking.form.party.values;
export const [partyIsSaving, partyHasSaveError, partySaveError] = getRequestStatus(SET_PARTY_DETAILS);

// Payment
export const payment = (state) => state.booking.form.payment;
export const paymentOption = (state) => state.booking.form.payment.paymentOption;

// Total cost
export const remainingBalance = (state) => {
    const _totalCost = +(existingTotalCost(state) || '0');
    const _amountPaid = +(amountPaid(state) || '0');
    return _totalCost - _amountPaid;
};

export const totalCostDeposit = (state) => {
    const _deposit = deposit(state) || 0;
    return parseFloat(_deposit) + selectedExtrasDepositTotal(state);
};

export const totalCostFull = (state) => {
    const _unitPrice = unitPrice(state) || 0;
    const _discountValue = discountValue(state) || 0;
    return parseFloat(_unitPrice - _discountValue) + selectedExtrasTotal(state);
};

export const totalCost = (state) => {
    const _depositAvailable = depositAvailable(state);
    const _paymentOption = paymentOption(state);

    return _depositAvailable && _paymentOption === 'deposit' ? totalCostDeposit(state) : totalCostFull(state);
};

export const totalCostRemaining = (state) => {
    const _bookingType = type(state);
    if (_bookingType === 'new') {
        return totalCostFull(state) - totalCost(state);
    }
    return remainingBalance(state);
};

export const paymentAmount = (state) => {
    const _bookingType = type(state);
    if (_bookingType === 'new') {
        return paymentOption(state) === 'full' ? totalCostFull(state) : totalCostDeposit(state);
    }
    return remainingBalance(state);
};
