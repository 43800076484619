import { GET_EXTRAS } from './action-types';
import { SUCCESS } from '../../constants';

const initialState = [];

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EXTRAS + SUCCESS:
            return action.payload.response.data.data.extras;
        default:
            return state;
    }
};

export default reducer;
