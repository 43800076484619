import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Badge, Button, Col, FormControl, Row } from 'react-bootstrap';
import CardPanel from '../CardPanel';
import { storeDiscountCode } from '../../redux/booking/booking/actions';
import { discountCode } from '../../redux/booking/booking/selectors';

function DiscountCode(props) {
    const [showDiscountCodeSuccess, setShowDiscountCodeSuccess] = useState(false);
    const discountInput = useRef(null);
    const reduxDiscountCode = useSelector(discountCode);
    const dispatch = useDispatch();

    const handleApplyDiscountCode = (code) => {
        storeDiscountCode(dispatch, code);
    };
    const handleRemoveDiscountCode = useCallback(() => {
        storeDiscountCode(dispatch, '');
        setShowDiscountCodeSuccess(false);
        discountInput.current.value = '';
    }, [dispatch]);

    useEffect(() => {
        discountInput.current.value = reduxDiscountCode;
    }, [reduxDiscountCode]);

    useEffect(() => {
        if (props.errorResponse?.errors.find(o => o.code === 39)) handleRemoveDiscountCode();
    }, [props.errorResponse, handleRemoveDiscountCode])

    return (
        <CardPanel title="Discount Code" className="mb-4">
            <Row>
                <Col>
                    {props.disabled ? (
                        <Alert variant="success" className="text-success light p-3">Your discount has been applied.</Alert>
                    ) : (
                        <p>If you have a Discount Code, please enter it here and it will be applied in the next step.</p>
                    )}
                    
                    <FormControl
                        type="text"
                        ref={discountInput}
                        onChange={() => {handleApplyDiscountCode(discountInput.current.value)}}
                        disabled={props.disabled}
                    />
                </Col>
                <Col md={3} className="d-none align-items-end">
                    <Button
                        block
                        className="mt-3"
                        onClick={() => {setShowDiscountCodeSuccess(true)}}
                    >
                        Apply Code
                    </Button>
                </Col>
            </Row>
            {reduxDiscountCode !== '' && showDiscountCodeSuccess && (
                <Row>
                    <Col>
                        <Alert variant="success" className="text-success mt-3 mb-0 light p-3">
                            <Row noGutters>
                                <Col>
                                    Discount code{' '}
                                    <code className="text-success">
                                        <strong>{reduxDiscountCode}</strong>
                                    </code>{' '}
                                    will be applied in the next step.
                                </Col>
                                <Col className="d-flex align-items-center justify-content-end">
                                    <Badge
                                        variant="success"
                                        className="cursor-pointer p-1"
                                        onClick={() => {
                                            handleRemoveDiscountCode();
                                        }}
                                    >
                                        Remove
                                    </Badge>
                                </Col>
                            </Row>
                        </Alert>
                    </Col>
                </Row>
            )}
        </CardPanel>
    );
}

export default DiscountCode;
