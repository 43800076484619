import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/user/actions';
import { loggedIn } from '../../redux/user/selectors';
import { config } from '../../../config';
import HeaderBar from './HeaderBar';
import LoginModal from '../Login/LoginModal';
import MegaMenuNav from './MegaMenu';

function Header() {
    const dispatch = useDispatch();
    const [hasMenuData, setHasMenuData] = useState(false);
    const [menuData, setMenuData] = useState();
    const [showLoginForm, setShowLoginForm] = useState(false);
    const _loggedIn = useSelector(loggedIn);
    const handleHideLoginForm = () => setShowLoginForm(false);
    const handleLogout = () => logout(dispatch);
    const handleShowLoginForm = () => setShowLoginForm(true);
    const menuJSON = '/json-files/' + config['header.megaMenu'];

    useEffect(() => {
        // Fetch menu json
        const controller = new AbortController();
        fetch(menuJSON, { signal: controller.signal })
            .then((response) => response.json())
            .then((data) => {
                setMenuData(data);
            })
            .catch((error) => {
                console.error('Error: ' + error.message);
            })
            .finally(() => {
                setHasMenuData(true);
            });
        return () => {
            controller.abort();
        };
    }, [menuJSON]);

    return hasMenuData && menuData !== undefined ? (
        <>
            <HeaderBar loggedIn={_loggedIn} onLogout={handleLogout} onShowLogin={handleShowLoginForm} />
            <MegaMenuNav
                loggedIn={_loggedIn}
                onLogout={handleLogout}
                onShowLogin={handleShowLoginForm}
                menuData={menuData}
            />
            <LoginModal show={showLoginForm} onClose={handleHideLoginForm} />
        </>
    ) : (
        <></>
    );
}

export default Header;
