import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Container, Row } from 'react-bootstrap';

// import FacilitiesIcon from '../FacilitiesIcon';
import ParkImage from './ParkImage';
import ParkLink from './ParkLink';
import ParkResultCountPrice from './ParkResultCountPrice';

import { config } from '../../../config';
import { useSelector } from 'react-redux';
import { getSpecificOperatorName } from '../../redux/data/operators/selectors';

function ParkCard({
    channelId,
    regionId,
    operator,
    park,
    minPrice,
    altDateMinPrice,
    dateMatchCount,
    altDateCount,
    url,
    //visible,
    parkRoute,
    viewMode,
}) {
    const isSticky = park.pri === 1;
    const isPremium = park.pri === 2;
    const specialClass = isSticky ? ' is-sticky-result' : isPremium ? ' is-premium' : '';
    const specialButtonClass = isSticky ? 'btn-secondary' : 'btn-primary';
    const specialTextClass = isSticky ? 'text-secondary' : `text-${config['global.textHighlight']}`;
    const availableDates = !!(dateMatchCount || altDateCount);
    const linkPrice = minPrice === undefined ? altDateMinPrice : minPrice;

    // Get the opName used for boats in popular holidays parkcards
    const _opName = useSelector((state) => getSpecificOperatorName(state, park.opId));
    const operatorName = operator.name || _opName;

    return (
        <>
            {viewMode === 'list' && (
                <Container className={`single-park-result mb-4${specialClass}`}>
                    <Row>
                        <Col md={4} lg={5} className="p-0">
                            <ParkImage {...{ channelId, regionId, park, url, parkRoute, isSticky, isPremium }} />
                        </Col>

                        <Col md={8} lg={7} className="d-flex flex-column">
                            <Row className="my-3">
                                <Col>
                                    <h2>{channelId === 'boats' ? `${operatorName} - ${park.name}` : park.name}</h2>
                                    <p>
                                        <i className={`icon-map-marker ${specialTextClass}`}></i>
                                        &nbsp;{park.sAdd}
                                    </p>
                                </Col>
                            </Row>

                            <Row noGutters>
                                {Array.isArray(park.features) && (
                                    <ul>
                                        {park.features.map((feature, i) => (
                                            <li key={i} dangerouslySetInnerHTML={{ __html: feature }}></li>
                                        ))}
                                    </ul>
                                )}
                            </Row>

                            <Row className="single-park-result-cta align-items-center mt-auto border-top py-3">
                                <Col>
                                    {availableDates && (
                                        <ParkResultCountPrice
                                            countOnly={true}
                                            {...{
                                                dateMatchCount,
                                                altDateCount,
                                                minPrice,
                                                altDateMinPrice,
                                                specialTextClass,
                                            }}
                                        />
                                    )}
                                </Col>

                                <Col>
                                    <ParkLink smallText={false} minPrice={linkPrice} {...{ channelId, regionId, park, url, parkRoute, specialButtonClass }} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            )}
            {viewMode === 'grid' && (
                <Card className={`w-100 single-park-result grid-size${specialClass}`}>
                    <div className="position-relative">
                        <ParkImage {...{ channelId, regionId, park, url, parkRoute, isSticky, isPremium }} />
                        <div className="textOverlay bg-white-a90 w-100 p-2">
                            <p className="mb-0 text-black">
                                <i className={`icon-map-marker ${specialTextClass} `}></i>
                                &nbsp;{park.sAdd}
                            </p>
                            {availableDates && (
                                <ParkResultCountPrice
                                    countOnly={true}
                                    smallText={true}
                                    {...{
                                        dateMatchCount,
                                        altDateCount,
                                        minPrice,
                                        altDateMinPrice,
                                        specialTextClass,
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <Card.Body>
                        <h2 className="h3">{channelId === 'boats' ? `${operatorName} - ${park.name}` : park.name}</h2>
                        {Array.isArray(park.features) && (
                            <ul className="m-0">
                                {park.features.map((feature, i) => (
                                    <li key={i} dangerouslySetInnerHTML={{ __html: feature }}></li>
                                ))}
                            </ul>
                        )}
                    </Card.Body>
                    <Card.Footer className="single-park-result-cta">
                        <Row>
                            <Col>
                                <ParkLink
                                    smallText={true}
                                    minPrice={linkPrice}
                                    {...{ channelId, regionId, park, url, parkRoute, specialButtonClass }}
                                />
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>
            )}
        </>
    );
}

ParkCard.propTypes = {
    channelId: PropTypes.string,
    regionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    operator: PropTypes.object,
    park: PropTypes.object,
    minPrice: PropTypes.string,
    altDateMinPrice: PropTypes.string,
    dateMatchCount: PropTypes.number,
    altDateCount: PropTypes.number,
    features: PropTypes.array,
    url: PropTypes.string,
    visible: PropTypes.bool,
    parkRoute: PropTypes.string,
};

ParkCard.defaultProps = {
    features: [],
    operator: {},
    park: {},
};

export default memo(ParkCard);
