import React from 'react';
import PropTypes from 'prop-types';

import ExtrasFormOption from './ExtrasFormOption';
import t from '../../../../../text';

export const ExtrasForm = (props) => (
    <>
        {props.loading ? (
            t('global.loading')
        ) : (
            <>
                <p>{t('forms.extras.introText')}</p>
                {props.extras.length > 0 &&
                    props.extras.map((extra) => (
                        <ExtrasFormOption key={extra.code} className="mt-4" onChange={props.selectExtra} {...extra} />
                    ))}
            </>
        )}
    </>
);

ExtrasForm.defaultProps = {
    loading: false,
    extras: {},
};

ExtrasForm.propTypes = {
    loading: PropTypes.bool,
    extras: PropTypes.array,
    selectExtra: PropTypes.func,
};

export default ExtrasForm;
