import { connect } from 'react-redux';
import Party from './Party';

import { stepCompleted } from '../../../redux/booking/booking/actions';

import { BOOKING_STEPS } from '../../../Constants';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    stepCompleted: () => stepCompleted(dispatch, BOOKING_STEPS.PARTY),
});

export const PartyConnected = connect(mapStateToProps, mapDispatchToProps)(Party);
export default PartyConnected;
