import { useDispatch, useSelector } from 'react-redux';

import { updateUser } from '../../../../redux/user/actions';
import { details, updateUserError, updateUserFailed, updateUserPending } from '../../../../redux/user/selectors';

import useLocalFormState from '../../../../hooks/useLocalFormState';

export const useContactPreferencesFormActions = () => {
    const dispatch = useDispatch();
    return {
        updateUser: (values) => {
            return updateUser(dispatch, values);
        },
    };
};

export const useContactPreferencesFormState = () => {
    const _details = useSelector(details);
    const initialFormValues = {
        emailOpt: _details.emailOpt,
        smsOpt: _details.smsOpt,
        postOpt: _details.postOpt,
        thirdOpt: _details.thirdOpt,
    };

    return {
        ...useLocalFormState(undefined, initialFormValues),
        updateUserError: useSelector(updateUserError),
        updateUserFailed: useSelector(updateUserFailed),
        updateUserPending: useSelector(updateUserPending),
    };
};

export default useContactPreferencesFormState;
