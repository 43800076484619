import React from 'react';
import { Card } from 'react-bootstrap';
import Slider from 'react-slick';

function Videos(props) {
    const videoAttributes = '?byline=0&title=0&transparent=0';
    const SliderItems = props.content.map((video, i) => {
        return <iframe key={i} src={video + videoAttributes} title="Video" className="video-iframe"></iframe>;
    });

    return (
        <Card>
            <Card.Header>
                <h2 className="m-0">{`Video${props.count > 1 ? 's' : ''}`}</h2>
            </Card.Header>
            <Card.Body className="p-5">
                <Slider className="full-height-slides">{SliderItems}</Slider>
            </Card.Body>
        </Card>
    );
}

export default Videos;
