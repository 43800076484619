import { defaultConfig, getApi, getContentRoutes, getFooterLinks, getHeaderLinks } from '../../base/defaultConfig';
import { slugify } from '../../base/utils/string';

import logoDark from './images/logo-dark.svg';
import logoLight from './images/logo-light.svg';

export const config = {
    ...defaultConfig,
    'cms.url': '//uat-bfhs-wp.gfm.co.uk/wp-json/wp/v2',
    'global.brandName': 'Mail Holidays',
    'global.siteUrl': 'https://www.breakfreeholidays.co.uk',
    'global.email': 'enquiries@breakfreeholidays.co.uk',
    'global.textHighlight': 'secondary',
    'google.gtm': 'GTM-T762HDC',
    siteCode: 'MT',
    'socialMedia.telephone': 'tel:03332201937',
    'socialMedia.facebook': '',
    'socialMedia.instagram': '',
    'socialMedia.twitter': '',
    'socialMedia.youtube': '',
    'api.url': '',
    'user.optin.postOpt': true,
    'payment.enable3DSecure': true,
};

export const contentRoutes = [
    ...getContentRoutes(config),
    { path: '/privacy-policy', url: 'age/1904' },
    { path: '/customer-charter', url: 'age/1603' },
    { path: '/offers', url: 'age/1526' },
    { path: `/${slugify(config['global.brandName'])}-offers`, url: 'age/221' },
    { path: '/about-us', url: 'age/1516' },
    { path: '/cottages', url: 'age/662' },
    { path: '/european-holiday-parks', url: 'age/1597' },
    { path: '/holiday-home-sales', url: 'age/1386' },
    { path: '/hotels', url: 'age/664' },
];

const footerData = getFooterLinks(config);
const remove = ['hotels', 'cheapercaravan'];
const filteredLinks = footerData.findAHoliday1.links.filter((link) => !remove.includes(link.id));
export const footerLinks = {
    ...footerData,
    findAHoliday1: {
        ...footerData.findAHoliday1,
        links: filteredLinks,
    },
};

export const images = {
    headerLogoDark: logoDark,
    headerLogoLight: logoLight,
    logoDark: logoDark,
    logoLight: logoLight,
};

export const urls = {
    ...getApi(config, 'api'),
};

export const HeaderLinks = getHeaderLinks([1, 4, 6]);
