import React from 'react';
import PropTypes from 'prop-types';

import InputRadioGroup from '../../../FormElements/InputRadioGroup';
import t from '../../../../../text';
import { config } from '../../../../../config';

export const ContactPreferencesInputs = (props) => (
    <>
        {config['user.optin.emailOpt'] && (
            <InputRadioGroup
                invalid={!!props.errors?.emailOpt}
                name="emailOpt"
                onChange={props.onChange}
                options={[
                    { label: t('forms.contactPrefs.yesPlease'), value: 'Y' },
                    { label: t('forms.contactPrefs.noThanks'), value: 'N' },
                ]}
                text={t('forms.contactPrefs.emailOffers')}
                validationMessage={props.errors?.emailOpt}
                values={props.values}
            />
        )}
        {config['user.optin.smsOpt'] && (
            <InputRadioGroup
                invalid={!!props.errors?.smsOpt}
                name="smsOpt"
                onChange={props.onChange}
                options={[
                    { label: t('forms.contactPrefs.yesPlease'), value: 'Y' },
                    { label: t('forms.contactPrefs.noThanks'), value: 'N' },
                ]}
                text={t('forms.contactPrefs.smsOpt')}
                validationMessage={props.errors?.smsOpt}
                values={props.values}
            />
        )}
        {config['user.optin.postOpt'] && (
            <InputRadioGroup
                invalid={!!props.errors?.postOpt}
                name="postOpt"
                onChange={props.onChange}
                options={[
                    { label: t('forms.contactPrefs.yesPlease'), value: 'Y' },
                    { label: t('forms.contactPrefs.noThanks'), value: 'N' },
                ]}
                text={t('forms.contactPrefs.postOpt')}
                validationMessage={props.errors?.postOpt}
                values={props.values}
            />
        )}
        {config['user.optin.thirdOpt'] && (
            <InputRadioGroup
                groupClassName="mb-4"
                invalid={!!props.errors?.thirdOpt}
                name="thirdOpt"
                onChange={props.onChange}
                options={[
                    { label: t('forms.contactPrefs.yesPlease'), value: 'Y' },
                    { label: t('forms.contactPrefs.noThanks'), value: 'N' },
                ]}
                text={t('forms.contactPrefs.emailNewspaperOffers')}
                validationMessage={props.errors?.thirdOpt}
                values={props.values}
            />
        )}
    </>
);

ContactPreferencesInputs.propTypes = {
    errors: PropTypes.object,
    values: PropTypes.object,
    onChange: PropTypes.func,
};

export default ContactPreferencesInputs;
