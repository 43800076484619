import {
    SELECT_CHANNEL,
    SELECT_REGION,
    SELECT_DATE,
    SELECT_DURATION,
    SELECT_GUESTS_ADULTS,
    SELECT_GUESTS_CHILDREN,
    SELECT_GUESTS_UNDER5S,
    SELECT_PET_FRIENDLY,
    START_SEARCH,
    STORE_COTTAGE_DATES,
    RESET_ARRIVAL,
} from './action-types';
import { GET_RESULTS } from '../results/action-types';
import { RESET } from '../../constants';

import { apiRequest } from '../../apiRequest';
import selfCancellingAction from '../../selfCancellingAction';
import { urls } from '../../../../config';

export const selectChannel = (dispatch, channelId) => {
    dispatch({ type: SELECT_CHANNEL, payload: channelId });
};
export const selectRegion = (dispatch, regionId) => {
    dispatch({ type: SELECT_REGION, payload: regionId });
};
export const selectDate = (dispatch, date) => {
    dispatch({ type: SELECT_DATE, payload: date });
};
export const selectDuration = (dispatch, duration) => {
    dispatch({ type: SELECT_DURATION, payload: duration });
};
export const selectGuestsAdults = (dispatch, guests) => {
    dispatch({ type: SELECT_GUESTS_ADULTS, payload: guests });
};
export const selectGuestsChildren = (dispatch, guests) => {
    dispatch({ type: SELECT_GUESTS_CHILDREN, payload: guests });
};
export const selectGuestsUnder5s = (dispatch, guests) => {
    dispatch({ type: SELECT_GUESTS_UNDER5S, payload: guests });
};
export const selectPetFriendly = (dispatch, petFriendly) => {
    dispatch({ type: SELECT_PET_FRIENDLY, payload: petFriendly });
};
export const storeAllCottageDates = (dispatch, dates) => {
    const ttl = new Date(new Date().setHours(new Date().getHours() + 1));
    dates['ttl'] = ttl;
    dispatch({ type: STORE_COTTAGE_DATES, payload: dates });
};
export const resetArrival = (dispatch) => {
    dispatch({ type: RESET_ARRIVAL });
};

export const [startSearch, cancelSearch] = selfCancellingAction((dispatch, params, requestParams, parkId) => {
    dispatch({ type: GET_RESULTS + RESET });
    dispatch({ type: START_SEARCH, payload: { ...params, parkId } });
    return apiRequest(dispatch, START_SEARCH, urls.search, { ...requestParams, park: parkId });
});
