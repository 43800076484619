import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

export const Facilities = (props) => {
    return (
        <Card>
            <Card.Header>
                <h2 className="mb-0">{props.title}</h2>
            </Card.Header>
            <Card.Body className="facilities p-3">
                {props.overview && props.overview.length > 0 && (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: props.overview.replace(/&quot;/g, '"').replace(/<a\s/g, '<a target="_blank"'),
                        }}
                    />
                )}
                <ul className="facilities-list mb-4">
                    {props.facilities.map((facility, i) => (
                        <li key={i}>
                            <i className="icon-check text-secondary mr-2"></i>
                            <span dangerouslySetInnerHTML={{ __html: facility }} />
                        </li>
                    ))}
                </ul>
                {props.importantInfo && props.importantInfo.length > 0 && (
                    <div
                        className="bg-warning px-3 pt-3 pb-1 m-0"
                        dangerouslySetInnerHTML={{ __html: props.importantInfo }}
                    />
                )}
            </Card.Body>
        </Card>
    );
};

Facilities.propTypes = {
    facilities: PropTypes.array,
};

Facilities.defaultProps = {
    facilities: [' '],
};

export default Facilities;
