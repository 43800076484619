import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { parkId, processedResults } from '../../redux/park/selectors';
import { duration } from '../../redux/search/form/selectors';
import { createBooking } from '../../redux/booking/booking/actions';
import DealOfTheWeekBanner from '../../components/ParkPage/DealOfTheWeekBanner/DealOfTheWeekBanner';
import ParkTitleAndDesc from './ParkTitleAndDesc';
import ParkInformation from './ParkInformation';
import SearchBox from '../../components/SearchBox/SearchBox';
import ToolsModal from '../../components/ParkPage/ToolsModal';
import WithLoadingStates from '../../components/HOC/WithLoadingStates';
import WithLoginViaModal from '../../components/HOC/WithLoginViaModal';
import WithOperators from '../../components/HOC/WithOperators';
import WithParkDetails from '../../components/HOC/WithParkDetails';
import WithParkSelected from '../../components/ParkPage/WithParkSelected';
import WithPartyDetailsViaModal from '../../components/ParkPage/WithPartyDetailsViaModal';
import WithRegions from '../../components/HOC/WithRegions';
import t from '../../../text';

function ParkPage(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { channelId, regionId } = useParams();
    const _duration = useSelector(duration);
    const _parkId = useSelector(parkId);
    const _processedResults = useSelector(processedResults);
    const isBoat = channelId === 'boats';
    const searchResult = /(park-result|boat-result|deal-of-the-week|park-from-search)/.test(location.pathname);
    const handleCreateBooking = (data) => {
        createBooking(
            dispatch,
            channelId,
            _parkId,
            data.unit.unitId,
            data.unit.unitType,
            data.unit.unitPrice,
            _processedResults.selectedDate?.date,
            _duration,
            data.party,
        );
        history.push('/booking', { prevPath: window.location.pathname });
    };

    return (
        <>
            <Container fluid className="py-4 bg-dark">
                <SearchBox />
            </Container>

            <WithParkSelected>
                <WithParkDetails parkId={_parkId} regionId={regionId}>
                    <WithRegions channelId={channelId}>
                        <WithOperators channelId={channelId}>
                            <WithLoadingStates
                                loadingErrorMessage={t('park.errorLoading', isBoat)}
                                spinnerMessage={t('park.loading', isBoat)}
                                scrollToTop
                                useContainer
                            >
                                {props.dealOfTheWeek && <DealOfTheWeekBanner />}
                                <ParkTitleAndDesc />

                                <WithLoginViaModal onNext={handleCreateBooking}>
                                    <WithPartyDetailsViaModal>
                                        <ParkInformation searchResult={searchResult} />
                                    </WithPartyDetailsViaModal>
                                </WithLoginViaModal>
                            </WithLoadingStates>
                        </WithOperators>
                    </WithRegions>
                </WithParkDetails>
            </WithParkSelected>

            <ToolsModal />
        </>
    );
}

ParkPage.propTypes = {
    dealOfTheWeek: PropTypes.bool,
};

export default ParkPage;
