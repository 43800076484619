import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import t from '../../../../text';

const HolidayDateCard = ({ clicked, day, date, year, price, selected, preferred }) => (
    <Card
        className={`rounded cursor-pointer date-card ${selected && 'date-selected'} ${!preferred && 'alt-card'}`}
        onClick={clicked}
    >
        <Card.Body className="bg-white text-center rounded p-3">
            <p className="mb-0">
                {day}, {date} <br className="d-none d-md-inline d-xl-none" />
                {year}
            </p>
            <p className="font-weight-bold mb-0">{t('park.dates.fromX', price.toFixed(2))}</p>
        </Card.Body>
        <Card.Footer className="p-0 border-top">
            {selected ? (
                <p className="text-center text-uppercase font-weight-bold p-1 mb-0">{t('park.dates.selectedDate')}</p>
            ) : (
                <p className="text-center text-uppercase font-weight-bold p-1 mb-0">
                    {t('park.dates.dateType', preferred)}
                </p>
            )}
        </Card.Footer>
    </Card>
);

HolidayDateCard.propTypes = {
    clicked: PropTypes.func,
    date: PropTypes.string,
    day: PropTypes.string,
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    price: PropTypes.number,
    selected: PropTypes.bool,
    preferred: PropTypes.bool,
};

export default HolidayDateCard;
