import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import ContactPreferencesInputs from './ContactPreferencesInputs';
import t from '../../../../../text';
import { config } from '../../../../../config';
import { required } from '../../../../utils/validation';

export const validateFields = (values) => {
    const errors = {};
    if (!required(values.emailOpt) && config['user.optin.emailOpt']) {
        errors.emailOpt = t('forms.forms.noValueSelected');
    }
    if (!required(values.smsOpt) && config['user.optin.smsOpt']) {
        errors.smsOpt = t('forms.forms.noValueSelected');
    }
    if (!required(values.postOpt) && config['user.optin.postOpt']) {
        errors.postOpt = t('forms.forms.noValueSelected');
    }
    if (!required(values.thirdOpt) && config['user.optin.thirdOpt']) {
        errors.thirdOpt = t('forms.forms.noValueSelected');
    }
    return errors;
};

export const ContactPreferencesForm = (props) => (
    <Form name="contactPreferencesForm" noValidate>
        <p className="font-weight-bold">{t('forms.contactPrefs.stayUpdated')}</p>
        <ContactPreferencesInputs {...props} />
    </Form>
);

ContactPreferencesForm.propTypes = {
    values: PropTypes.object,
    onChange: PropTypes.func,
};

export default ContactPreferencesForm;
