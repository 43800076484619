import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { config } from '../../../config';
import A from '../A';

function ColumnSlider(props) {
    const maxSlides = props.parent === 'opRegions' ? 3 : 4;
    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        lazyLoad: true,
        speed: 500,
        slidesToShow: maxSlides,
        slidesToScroll: 1,
        className: 'homepage-slide',
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const sliderItems = props.items.map((item, index) => {
        return (
            <Card key={index}>
                {/^http/.test(item.url) ? (
                    <A href={item.url} className="d-block text-decoration-none outline-none" title={item.title}>
                        <Card.Img
                            className={`img-fluid ${props.extraClasses}`}
                            src={item.image}
                            alt={item.title}
                            loading="lazy"
                        />
                        <Card.Body className="p-3">
                            <h3 className={`text-${config['global.textHighlight']}`}>{item.title}</h3>
                            <p className="m-0 text-darker">{item.text}</p>
                        </Card.Body>
                    </A>
                ) : (
                    <Link to={item.url} className="d-block text-decoration-none outline-none">
                        <Card.Img className={`img-fluid ${props.extraClasses}`} src={item.image} alt={item.title} />
                        <Card.Body className="p-3">
                            <h3 className={`text-${config['global.textHighlight']}`}>{item.title}</h3>
                            <p className="m-0 text-darker">{item.text}</p>
                        </Card.Body>
                    </Link>
                )}
            </Card>
        );
    });

    return <Slider {...settings}>{sliderItems}</Slider>;
}

export default ColumnSlider;
