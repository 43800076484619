import React, { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet-async';
import ScrollToTop from '../../components/ScrollToTop';
import t from '../../../text';

const Hero = lazy(() => import('../../components/HomePage/Hero'));
const Banner = lazy(() => import('../../components/HomePage/Banner'));
const DealOfTheWeek = lazy(() => import('../../components/HomePage/DealOfTheWeek'));
const ColumnComponent = lazy(() => import('../../components/Common/ColumnComponent'));
const Countdown = lazy(() => import('../../components/HomePage/Countdown'));

function Home({ content }) {
    const components = content.map((block, index) => {
        if (block.type === 'Countdown' && block.data.status === false) return <div key={index}></div>; // This shouldn't be here but getting inactive countdowns?!
        switch (block.type) {
            case 'HeroText':
                return (
                    <Suspense fallback={<div className="masthead-loading"></div>} key={`suspense-${index}`}>
                        <Hero
                            key={index}
                            heading={block.data.heading}
                            subheading={block.data.subheading}
                            heroImage={block.data.hero_image}
                        />
                    </Suspense>
                );
            case 'Banner':
                return (
                    <Suspense fallback={<div></div>} key={`suspense-${index}`}>
                        <Banner
                            key={index}
                            fluid={block.data.fluid}
                            bg={block.data.bg}
                            text={block.data.text}
                            content={block.data.content}
                            url={block.data.url}
                        />
                    </Suspense>
                );
            case 'Countdown':
                return (
                    <Suspense fallback={<div></div>} key={`suspense-${index}`}>
                        <Countdown
                            key={index}
                            datetime={block.data.datetime}
                            title={block.data.title}
                            text={block.data.text}
                            linktext={block.data.linktext}
                            url={block.data.url}
                            background_colour={block.data.background_colour}
                            text_colour={block.data.text_colour}
                        />
                    </Suspense>
                );
            case 'HomeColumns':
                return (
                    <Suspense fallback={<div></div>} key={`suspense-${index}`}>
                        <ColumnComponent key={index} heading={block.data.heading} columns={block.data.columns} />
                    </Suspense>
                );
            case 'DealOfTheWeek':
                return (
                    <Suspense fallback={<div></div>} key={`suspense-${index}`}>
                        <DealOfTheWeek
                            key={index}
                            park={block.data.park}
                            date={block.data.date}
                            nights={block.data.nights}
                            price={block.data.price}
                            heading={block.data.heading}
                            variant={block.data.variant}
                        />
                    </Suspense>
                );
            default:
                return <div key={index}></div>;
        }
    });

    return (
        <>
            <Helmet>
                <title key="title">{t('home.page.title')}</title>
            </Helmet>
            <ScrollToTop />
            {components}
        </>
    );
}

export default Home;
