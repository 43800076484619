import React from 'react';
import { Link } from 'react-router-dom';
import A from '../A';

function MegaMenuColumn({section, setExpanded}) {
    const getLink = (text, url) => {
        let path = url || false;
        if (!path) return text;
        if (/^http.*/gi.test(path)) return <A href={path}>{text}</A>;
        return (
            <Link to={path} onClick={() => setExpanded(false)}>
                {text}
            </Link>
        );
    };

    return  (
        <>
            <p className="lead mb-2 megamenu-heading">{section.title}</p>
            <ul className="list-unstyled">
                {section.links.map((link, i) => {
                    return (
                        <li key={i} className="mb-3">{getLink(link.text, link.url)}</li>
                    );
                })}
            </ul>
        </>
    );
}

export default MegaMenuColumn;
