import { CREATE_USER, GET_BOOKINGS, LOGIN, LOGOUT, UPDATE_USER } from './action-types';
import { SUCCESS } from '../constants';
import { COMPLETE_BOOKING } from '../booking/booking/action-types';

const initialState = {
    loggedIn: false,
    bookings: undefined,
    details: {},
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_USER + SUCCESS:
        case UPDATE_USER + SUCCESS:
        case LOGIN + SUCCESS:
            return {
                ...state,
                loggedIn: true,
                details: {
                    ...action.payload.response.data.data,
                    dobMnth: parseInt(action.payload.response.data.data.dobMnth).toString(), // Hack to work around padded values from server
                },
            };
        case LOGOUT + SUCCESS:
            return initialState;
        case GET_BOOKINGS + SUCCESS:
            return {
                ...state,
                bookings: action.payload.response.data.data.bookings,
            };
        case COMPLETE_BOOKING:
            return {
                ...state,
                bookings: undefined,
            };
        default:
            return state;
    }
};

export default reducer;
