import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Form } from 'react-bootstrap';

export const ChannelSelect = (props) => {
    const [selectedChannel, setSelectedChannel] = useState({ name: '' });

    useEffect(() => {
        setSelectedChannel(props.channels.find((channel) => channel.code === props.selectedValue));
    }, [props.channels, props.selectedValue]);

    return (
        <>
            <Form.Group controlId={props.id}>
                <Form.Label className={`text-${props.text}`}>
                    <span>{props.label}</span>
                </Form.Label>
                <Dropdown show={props.show} onToggle={props.onToggle} disabled={props.disabled}>
                    <Dropdown.Toggle
                        as="button"
                        type="button"
                        className="toggle-popup is-dropdown"
                        role="button"
                        tabIndex="0"
                        disabled={props.disabled}
                    >
                        <span>{selectedChannel?.name}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="a-popup p-0 pt-3" tabIndex="-1">
                        {/*
                        <Form.Control as="select" className="border-0 p-0" tabIndex="1" onChange={props.onChange}>
                            {props.channels.map((channel) => (
                                <option
                                    key={channel.code}
                                    value={channel.code}
                                    className="py-2 pl-3"
                                    disabled={props.whitelist.length && !props.whitelist.includes(channel.code)}
                                >
                                    {channel.name}
                                </option>
                            ))}
                        </Form.Control>
                        */}

                        {props.channels
                            .filter((channel) => props.whitelist.includes(channel.code))
                            .map((channel) => (
                                <Form.Check
                                    key={channel.code}
                                    id={channel.code}
                                    type="radio"
                                    label={channel.name}
                                    name="channelSelections"
                                    value={channel.code}
                                    onChange={props.onChange}
                                    disabled={props.whitelist.length && !props.whitelist.includes(channel.code)}
                                    className="bf-radio"
                                    checked={selectedChannel?.code === channel.code}
                                />
                            ))}
                    </Dropdown.Menu>
                </Dropdown>
            </Form.Group>
        </>
    );
};

ChannelSelect.defaultProps = {
    text: 'white',
    whitelist: [],
};

ChannelSelect.propTypes = {
    id: PropTypes.string,
    textStyle: PropTypes.string,
    label: PropTypes.string,
    channels: PropTypes.array,
    selectedValue: PropTypes.string,
    onChange: PropTypes.func,
    whitelist: PropTypes.array,
    disabled: PropTypes.bool,
};

export default ChannelSelect;
