import React, { Children, cloneElement, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { channelId } from '../../redux/park/selectors';
import PartyModal from '../../pages/Booking/Party/Party';

export const WithPartyDetailsViaModal = (props) => {
    const _channelId = useSelector(channelId);
    const [showPartyModal, setShowPartyModal] = useState(false);
    const [data, setData] = useState({});

    const handleSubmit = (values) => {
        setShowPartyModal(false);
        props.onNext({ ...data, party: values });
    };

    const onNext = useCallback(
        (newData) => {
            if (_channelId === 'eurocaravan') {
                setData(newData);
                setShowPartyModal(true);
            } else {
                props.onNext(newData);
            }
        },
        [_channelId, props],
    );

    return (
        <>
            {_channelId === 'eurocaravan' && (
                <PartyModal
                    onClose={() => setShowPartyModal(false)}
                    onSubmit={handleSubmit}
                    show={showPartyModal}
                    useModal
                    useDob
                />
            )}
            {Children.map(props.children || [], (child) => {
                return cloneElement(child, {
                    ...props,
                    ...child.props,
                    onNext,
                });
            })}
        </>
    );
};

WithPartyDetailsViaModal.propTypes = {
    onNext: PropTypes.func,
};

export default WithPartyDetailsViaModal;
