import React from 'react';
import { Card } from 'react-bootstrap';
import SingleLocation from '../../Maps/SingleLocation';

function Location(props) {
    return (
        <>
            <Card>
                <Card.Header>
                    <h2 className="m-0">Location</h2>
                </Card.Header>
                <Card.Body className="p-3">
                    <SingleLocation position={props.position} name={props.name} opid={props.opid} />
                </Card.Body>
            </Card>
            {props.directions.length > 0 && (
                <Card>
                    <Card.Header>
                        <h2 className="m-0">Directions</h2>
                    </Card.Header>
                    <Card.Body className="p-3">
                        <div dangerouslySetInnerHTML={{ __html: props.directions }}></div>
                    </Card.Body>
                </Card>
            )}
        </>
    );
}

export default Location;
