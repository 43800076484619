import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import t from '../../../text';

export const RegionSelect = (props) => {
    const RegionNames = useSelector((state) => state.data.regions.regions);

    const [RegionName, setRegionName] = useState('');

    useEffect(() => {
        if (!props.selectedValue) setRegionName('Loading...');
        else setRegionName(RegionNames[props.selectedValue]?.name);
    }, [RegionNames, props.selectedValue]);

    let regionOptions;
    if (!props.channelSelected) {
        regionOptions = null;
    } else if (props.regionsLoading) {
        regionOptions = (
            <option value="2" disabled>
                {t('search.loadingRegions')}
            </option>
        );
    } else if (props.regions) {
        regionOptions = props.regions.map((region) => (
            //<option key={region.id} value={region.id} className="py-2 pl-3">
            //    {region.name}
            //</option>

            <Form.Check
                key={region.id}
                id={region.id}
                type="radio"
                label={region.name}
                name="regionSelections"
                value={region.id}
                onChange={props.onChange}
                disabled={!props.channelSelected}
                className="bf-radio"
                checked={props.selectedValue === region.id}
            />
        ));
    }

    return (
        <Form.Group controlId={props.id}>
            <Form.Label className={`text-${props.textStyle}`}>
                <span>{props.label}</span>
            </Form.Label>
            <Dropdown show={props.show} onToggle={props.onToggle}>
                <Dropdown.Toggle
                    as="button"
                    type="button"
                    className="toggle-popup is-dropdown"
                    role="button"
                    tabIndex="0"
                    disabled={props.disabled}
                >
                    <span>{props.regionsLoading ? '' : RegionName}</span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="a-popup p-0 pt-3">
                    {/*
                    <Form.Control
                        as="select"
                        multiple
                        className="border-0 p-0"
                        value={props.regionsLoading ? '' : props.selectedValue}
                        onChange={props.onChange}
                        disabled={!props.channelSelected}
                        tabIndex="0"
                    >
                    </Form.Control>
                    */}
                    {regionOptions}
                </Dropdown.Menu>
            </Dropdown>
        </Form.Group>
    );
};

RegionSelect.defaultProps = {
    text: 'white',
    whitelist: [],
};

RegionSelect.propTypes = {
    id: PropTypes.string,
    textStyle: PropTypes.string,
    label: PropTypes.string,
    channelSelected: PropTypes.bool,
    regions: PropTypes.array,
    regionsLoading: PropTypes.bool,
    selectedValue: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

export default RegionSelect;
