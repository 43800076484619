import React, { useState, Suspense, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Card, Form, Collapse, Button } from 'react-bootstrap';

import AppStateFilterPanel from './AppStateFilterPanel';
import AppStateSortPanel from './AppStateSortPanel';
import MultipleLocations from '../Maps/MultipleLocations';
import PageTitle from '../PageTitle';
//import RangeSlider from '../Common/RangeSlider';

import { getState } from '../../redux/appState/selectors';
import t from '../../../text';
import useFilters from './useFilters';
import FetchSeoSearchText from '../SearchResults/FetchSeoSearchText';
import { getNFilters } from '../../redux/data/filters/selectors';
import { channels } from '../../redux/data/channels/selectors';
import { setState } from '../../redux/appState/actions';
import { towns } from '../../redux/data/parks/selectors';
import TownsList from './TownsList';
//import { contentRoutes } from '../../../config';

// Component
export const ParksListFilterView = (props) => {
    const filtersColumn = useRef();
    const dispatch = useDispatch();
    const nFiltersStateKey = 'nFiltersStateArray';
    const bFiltersStateKey = 'bFiltersStateArray';
    const [activeFilters, setActiveFilters] = useState({ nFilt: [0, 0], bFilt: [] });
    const [maxNFiltValues, setMaxNFiltValues] = useState(null);
    const [collapseFilters, setCollapseFilters] = useState(Boolean(window.innerWidth >= 992));
    const channelsData = useSelector(channels);
    const townsList = useSelector(towns);
    const correctChannel = channelsData.find((channel) => channel.code === props.channelId);
    const filterGroup = correctChannel.filterSet;
    const allFilters = useSelector((state) => state.data.filters.filters);
    const chanFilterSet = useSelector((state) => state.data.filters.filterGroups[correctChannel.filterSet]);
    const nFilt = useSelector((state) => getNFilters(state))[filterGroup];
    const selectedNFilters = useSelector((state) => getState(state, nFiltersStateKey));
    const selectedBFilters = useSelector((state) => getState(state, bFiltersStateKey));

    if (!Array.isArray(selectedNFilters) || selectedNFilters.length < 1) {
        setState(
            dispatch,
            nFiltersStateKey,
            Array(Object.values(allFilters).filter((o) => o.type === 'nFilt').length).fill(0),
        );
    }

    const updateFilters = (type, value, idx) => {
        if (window.innerWidth >= 992) {
            /* TODO:
                1. Move this to utils
                2. Make it a scrollTo ref function
                3. Include viewport size options
                    e.g. scroll to on lg or higher
            */
            /*filtersColumn.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
            });*/
        }

        if (type === 'bFilt') {
            let current = selectedBFilters;
            !current.includes(parseInt(value))
                ? current.push(parseInt(value))
                : current.splice(current.indexOf(parseInt(value)), 1);
            setState(dispatch, bFiltersStateKey, current);
        }

        if (type === 'nFilt') {
            let current = selectedNFilters;
            current[idx] = parseInt(value);
            setState(dispatch, nFiltersStateKey, current);

            /*
            const onToggleFilter = (key, arrPos) => {
                if (typeof arrPos === 'number') {
                    const nFiltLength = props.nFiltLength;
                    key = parseInt(key);
                
                    const nFilt = props.selectedFilters;
                
                    const arrayPosIsKey = nFilt[arrPos] === key;
                    nFilt[arrPos] = arrayPosIsKey ? -1 : key;
                
                    // Ensure array is correct length and values are integer or -1
                    for (let i = 0; i < nFiltLength; i++) {
                        nFilt[i] = nFilt[i] || -1;
                    }
                
                    const newValue = [...nFilt];
                
                    setState(dispatch, props.stateKey, newValue);
                }

                if (typeof arrPos === 'undefined') {
                    if (props.selectedFilters.includes(key)) {
                        setState(
                            dispatch,
                            props.stateKey,
                            props.selectedFilters.filter((val) => val !== key),
                        );
                    } else {
                        setState(dispatch, props.stateKey, [...props.selectedFilters, key]);
                    }
                }
            };
            */
        }
        if (activeFilters) setActiveFilters([]); // Required, forces useFilters hook to run for some reason?
    };

    // Get an array of max values for all nFillters
    useEffect(() => {
        if (maxNFiltValues !== null) return;
        const getMaxValues = () => {
            if (!props.results.length) return [];

            // Setup initial array with zero values to the length of nFilts for this product type.
            const arrLength = Array.isArray(props.results[0].park.nFilt) ? props.results[0].park.nFilt.length : 0;
            let maxValues = Array(arrLength).fill(0);
            props.results.forEach((result) => {
                let neverNull = result.park.nFilt === null ? Array(arrLength).fill(0) : result.park.nFilt;
                neverNull.forEach((val, i) => {
                    if (val > maxValues[i]) maxValues[i] = val;
                });
            });
            return maxValues;
        };
        setMaxNFiltValues(getMaxValues());
    }, [maxNFiltValues, props.results]);

    const appliedSortStateKey = props.id + 'AppliedSort';
    //const standardFiltersStateKey = props.id + 'StandardFilters';
    const operatorFiltersStateKey = props.id + 'OperatorFilters';
    const petFriendlyFiltersStateKey = props.id + 'PetFriendlyFilters';
    const appliedSort = useSelector((state) => getState(state, appliedSortStateKey));
    //const selectedStandardFilters = useSelector((state) => getState(state, standardFiltersStateKey));
    const selectedOperatorFilters = useSelector((state) => getState(state, operatorFiltersStateKey));
    const selectedPetFriendlyFilters = useSelector((state) => getState(state, petFriendlyFiltersStateKey));

    const [operatorFilters, petFriendlyFilters, sortOptions, filteredResults] = useFilters(
        props.results, // 0
        props.channelId, // 1
        //selectedStandardFilters,                // 2
        selectedOperatorFilters, // 3
        selectedPetFriendlyFilters, // 4
        selectedNFilters, // 5
        selectedBFilters, // 6
        nFilt, // 7
        props.allowSorting ? appliedSort : false, // 8
        props.sortByName, // 9
        props.isTown, //10
    );

    const [viewMode, setViewMode] = useState('grid');
    const url = new URL(window.location.href);
    const petSearch = url.searchParams.get('dogs') === 'y'; // bool. Hides pet filter if true

    useEffect(() => {
        const showFiltersForLarge = () => {
            if (/iPad|iPhone|iPod/.test(navigator.platform)) return;
            let timeout;
            window.clearInterval(timeout);
            timeout = setTimeout(() => setCollapseFilters(Boolean(window.innerWidth >= 768)), 200); // debounce for performance
        };
        window.addEventListener('resize', showFiltersForLarge);
        return () => {
            window.removeEventListener('resize', showFiltersForLarge);
        };
    });

    // Get list of unique towns
    const getTowns = (fr) => {
        let temp = [];
        fr.forEach((park) => {
            if ('towns' in park.park) {
                park.park.towns.forEach((town) => {
                    temp = [
                        ...temp,
                        {
                            id: town,
                            name: townsList[town],
                        },
                    ];
                });
            }
        });

        const uniq = new Set(temp.map((e) => JSON.stringify(e)));
        const res = Array.from(uniq).map((e) => JSON.parse(e));

        return [
            ...new Set(
                res.sort((a, b) => {
                    let fa = a.name.toLowerCase();
                    let fb = b.name.toLowerCase();
                    if (fa < fb) return -1;
                    if (fa > fb) return 1;
                    return 0;
                }),
            ),
        ];
    };

    return (
        <>
            <PageTitle title={props.title} viewMode={viewMode} onChangeViewMode={setViewMode} />
            <div ref={filtersColumn}>
                <Suspense fallback={<div className="text-center">Loading...</div>}>
                    <FetchSeoSearchText region={props.regionId} isArea={props.isTown} />
                </Suspense>
            </div>

            <Container>
                <Row>
                    <Col md={3}>
                        <Collapse in={collapseFilters}>
                            <div id="collapse-filters-inner">
                                {props.allowSorting && (
                                    <>
                                        <AppStateSortPanel
                                            stateKey={appliedSortStateKey}
                                            title={t('parks.filters.sortBy')}
                                            sortOptions={sortOptions}
                                            appliedSort={appliedSort}
                                        />
                                        <hr className="border-dotted my-3" />
                                    </>
                                )}
                                {operatorFilters.length > 1 &&
                                    !['boats', 'ukcottages'].includes(correctChannel.code) &&
                                    !props.isTown && (
                                        <AppStateFilterPanel
                                            stateKey={operatorFiltersStateKey}
                                            className="mb-3"
                                            title={t('parks.filters.filterByOperator')}
                                            filters={operatorFilters}
                                            selectedFilters={selectedOperatorFilters}
                                            id="operator-filter"
                                            idKey="opId"
                                            urlKey="operators"
                                            scrollTo={filtersColumn}
                                        />
                                    )}

                                {Object.keys(chanFilterSet).map((set, i) => {
                                    return (
                                        <Card key={i} className="product-filter-card mb-3">
                                            <Card.Header className="lead">{chanFilterSet[set]}</Card.Header>
                                            <Card.Body>
                                                {Object.values(allFilters)
                                                    .filter(
                                                        (f) =>
                                                            parseInt(f.filterSet) === parseInt(filterGroup) &&
                                                            parseInt(f.filterGroup) === parseInt(set),
                                                    )
                                                    .map((f, i) => {
                                                        return (
                                                            <div key={i}>
                                                                {'compare' in f ? ( // is Number type
                                                                    <Form.Group controlId={`filter-${f.id}`}>
                                                                        <Form.Label className="mb-2">
                                                                            {f.name}
                                                                        </Form.Label>
                                                                        <Row noGutters className="mb-3">
                                                                            <Col
                                                                                xs={9}
                                                                                className="d-flex align-items-center justify-content-center"
                                                                            >
                                                                                <Form.Control
                                                                                    type="range"
                                                                                    min={0}
                                                                                    max={
                                                                                        Array.isArray(maxNFiltValues)
                                                                                            ? maxNFiltValues[f.value]
                                                                                            : 1
                                                                                    }
                                                                                    step={1}
                                                                                    className="range-slider"
                                                                                    value={selectedNFilters[f.value]}
                                                                                    onChange={(e) =>
                                                                                        updateFilters(
                                                                                            f.type,
                                                                                            e.target.value,
                                                                                            f.value,
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                            <Col
                                                                                xs={3}
                                                                                className="d-flex align-items-center justify-content-center"
                                                                            >
                                                                                <span styles={{ fontSize: '1.125rem' }}>
                                                                                    <strong>
                                                                                        {selectedNFilters[f.value]}+
                                                                                    </strong>
                                                                                </span>
                                                                            </Col>
                                                                        </Row>
                                                                    </Form.Group>
                                                                ) : (
                                                                    // is Bool type
                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        label={f.name}
                                                                        id={`filter-${f.id}`}
                                                                        checked={selectedBFilters.includes(f.value)}
                                                                        onChange={() => updateFilters(f.type, f.value)}
                                                                    />
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                            </Card.Body>
                                        </Card>
                                    );
                                })}

                                {props.channelId !== 'eurocaravan' && (
                                    <AppStateFilterPanel
                                        stateKey={petFriendlyFiltersStateKey}
                                        className={`mb-3${petSearch ? ' d-none' : ''}`}
                                        title={t('parks.filters.filterByPetFriendly')}
                                        filters={petFriendlyFilters}
                                        selectedFilters={selectedPetFriendlyFilters}
                                        id="standard-filter"
                                        urlKey="dogs"
                                        scrollTo={filtersColumn}
                                    />
                                )}
                            </div>
                        </Collapse>
                        <Button
                            block
                            variant="light"
                            className="mb-3 d-md-none"
                            size="sm"
                            onClick={() => setCollapseFilters(!collapseFilters)}
                            aria-controls="collapse-filters-inner"
                            aria-expanded={collapseFilters}
                        >
                            {`${collapseFilters ? 'Hide' : 'Show'} filters`}
                        </Button>
                        {!props.isTown && correctChannel.code === 'ukcaravan' && props.regionId && (
                            <TownsList
                                towns={getTowns(filteredResults)}
                                region={props.regionId}
                                channel={correctChannel.code}
                            />
                        )}
                    </Col>

                    <Col md={9} style={{ minHeight: '100vh' }}>
                        {filteredResults.length ? (
                            <>
                                {viewMode === 'list' &&
                                    filteredResults.map((result) => props.generateOutput(result, viewMode))}
                                {viewMode === 'grid' && (
                                    <Row xs={1} sm={2} lg={3}>
                                        {filteredResults.map((result) => (
                                            <Col key={result.park.parkId} className="d-flex">
                                                {props.generateOutput(result, viewMode)}
                                            </Col>
                                        ))}
                                    </Row>
                                )}
                                {viewMode === 'map' && (
                                    <MultipleLocations
                                        channelId={props.channelId}
                                        regionId={props.regionId}
                                        results={filteredResults}
                                        parkRoute={props.parkRoute}
                                        removeMapCardMid={props.removeMapCardMid}
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                {props.noResults || (
                                    <p className="alert alert-warning">&#x26A0; {t('parks.filters.noResults')}</p>
                                )}
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
};

ParksListFilterView.propTypes = {
    id: PropTypes.string,
    channelId: PropTypes.string,
    regionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    results: PropTypes.array,
    parkRoute: PropTypes.string,
    generateOutput: PropTypes.func,
    allowSorting: PropTypes.bool,
};

ParksListFilterView.defaultProps = {
    id: '',
    channelId: 'ukcaravan',
    results: [],
    parkRoute: 'park-result',
};

export default ParksListFilterView;
