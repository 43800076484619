import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { loggedIn } from '../../redux/user/selectors';

import CreateUserFormView from './views/CreateUserFormView';
import LoggedInView from './views/LoggedInView';
import LoginFormView from './views/LoginFormView';
import PasswordResetFormView from './views/PasswordResetFormView';

function Login(props) {
    const history = useHistory();
    const [viewMode, setViewMode] = useState('login'); // One of login|register|loggedin|reset
    const _loggedIn = useSelector(loggedIn);

    const redirectToPage = () => {
        if (_loggedIn && props.redirectOnLogin) {
            const params = new URLSearchParams(history.location.search);
            const redirect = params.get('redirect');
            history.replace(redirect || '/my-account');
        }
    };

    useEffect(redirectToPage, [props.redirectOnLogin, history, _loggedIn]);

    return (
        <>
            {_loggedIn ? (
                <LoggedInView />
            ) : (
                <>
                    {viewMode === 'login' && (
                        <LoginFormView
                            onViewRegister={() => setViewMode('register')}
                            onViewReset={() => setViewMode('reset')}
                        />
                    )}
                    {viewMode === 'register' && <CreateUserFormView onBack={() => setViewMode('login')} />}
                    {viewMode === 'reset' && (
                        <PasswordResetFormView onBack={() => setViewMode('login')} handleClose={props.handleClose} />
                    )}
                </>
            )}
        </>
    );
}

Login.propTypes = {
    redirectOnLogin: PropTypes.bool,
};

Login.defaultProps = {
    redirectOnLogin: true,
};

export default Login;
