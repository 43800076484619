import React from 'react';
import { Badge } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { slugify } from '../../utils/string';
import t from '../../../text';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { config } from '../../../config';

export const ParkImage = ({ channelId, regionId, park, url, parkRoute, isSticky, isPremium }) => {
    const boatBgImage = `//content.breakfreeholidays.co.uk/images/boats/routes/${park.parkId}.jpg`;
    const internalUrl = `/${parkRoute}/${channelId}/${regionId}/${park.parkId}/${slugify(park.name)}`;

    //const bgImage = channelId === 'boats' ? boatBgImage : park.img;

    const linkUrl = url ? url + `${/\?/.test(url) ? '&' : '?'}utm_campaign=${config['siteCode']}` : internalUrl;

    const images = [];
    images.push(park.img);

    // Boats Images
    if (channelId === 'boats') images.push(boatBgImage);

    const SliderItems = images.map((img, i) => {
        let image = <img className="img-fluid w-100" src={img} alt="Images" loading="lazy" />;
        if (url) {
            return (
                <a href={linkUrl} key={i} className="d-block" target="_blank" rel="noopener noreferrer">
                    {image}
                </a>
            );
        } else {
            return (
                <Link to={linkUrl} key={i} className="d-block">
                    {image}
                </Link>
            );
        }
    });
    return (
        <div className="park-image">
            {isSticky && (
                <Badge pill variant="secondary text-darker position-absolute">
                    {t('searchResults.sticky')}
                </Badge>
            )}
            {isPremium && (
                <Badge pill variant="primary text-white">
                    {t('searchResults.premium')}
                </Badge>
            )}
            <Slider className="search-result-slider">{SliderItems}</Slider>
        </div>
    );
};

ParkImage.propTypes = {
    channelId: PropTypes.string,
    regionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    park: PropTypes.object,
    url: PropTypes.string,
    parkRoute: PropTypes.string,
    isSticky: PropTypes.bool,
    isPremium: PropTypes.bool,
};

export default ParkImage;
