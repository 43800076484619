import React from 'react';
import PropTypes from 'prop-types';

import { Card, Form } from 'react-bootstrap';

export const FilterPanel = ({
    filters,
    className,
    title,
    idKey,
    onToggleFilter,
    selectedFilters,
    onClearFilters,
    arrPos,
}) => {
    //const filtersChecked = filters && filters.find((filter) => filter.selected);

    const evaluateChecked = (filter, arrPos) => {
        if (arrPos !== undefined) {
            return selectedFilters[arrPos] === filter[idKey];
        }
        return selectedFilters.includes(filter[idKey]);
    };

    return (
        <Card className={`bf-filter product-filter-card ${className || ''}`}>
            <Card.Header className="lead">{title}</Card.Header>

            <Card.Body>
                <Form>
                    {filters.map((filter) => (
                        <Form.Check
                            key={filter[idKey]}
                            type={'checkbox'}
                            data-filter-id={filter[idKey]}
                            label={filter.label || filter.name}
                            onChange={() => onToggleFilter(filter[idKey], arrPos)}
                            checked={evaluateChecked(filter, arrPos)}
                        />
                    ))}
                </Form>
            </Card.Body>

            {/*filtersChecked && (
                <Card.Footer className="py-2">
                    <span className="text-danger cursor-pointer" onClick={onClearFilters}>
                        <i className="icon-close mr-1"></i> Clear
                    </span>
                </Card.Footer>
            )*/}
        </Card>
    );
};

FilterPanel.defaultProps = {
    filters: [],
    selectedFilters: [],
    idKey: 'id',
};

FilterPanel.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    filters: PropTypes.array,
    selectedFilters: PropTypes.array,
    onToggleFilter: PropTypes.func,
    onClearFilters: PropTypes.func,
    id: PropTypes.string,
    idKey: PropTypes.string,
};

export default FilterPanel;
