import { useSelector } from 'react-redux';
import { channelId, stepsCompleted, type } from '../../redux/booking/booking/selectors';
import { BOOKING_STEPS } from '../../Constants';

const standardSequence = [
    BOOKING_STEPS.EXTRAS,
    BOOKING_STEPS.DETAILS,
    BOOKING_STEPS.PARTY,
    BOOKING_STEPS.PAYMENT,
    BOOKING_STEPS.PAYMENT_ACTION,
];
const euroSequence = [BOOKING_STEPS.EXTRAS, BOOKING_STEPS.DETAILS, BOOKING_STEPS.PAYMENT, BOOKING_STEPS.PAYMENT_ACTION];
const existingBookingSequence = [BOOKING_STEPS.PAYMENT, BOOKING_STEPS.PAYMENT_ACTION];

export const useNextStep = (current = false) => {
    const _channelId = useSelector(channelId);
    const _stepsCompleted = useSelector(stepsCompleted);
    const _bookingType = useSelector(type);
    let sequence;

    if (_bookingType === 'existing') {
        sequence = existingBookingSequence;
    } else {
        sequence = _channelId === 'eurocaravan' ? euroSequence : standardSequence;
    }

    return sequence.filter((step) => !_stepsCompleted.includes(step))[current ? 0 : 1];
};

export default useNextStep;
