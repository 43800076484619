/**
 * Calculates how old a person is from their date of birth
 * @param {date} dob - The date to calculate the age from
 */
export function calculateAge(dob) {
    const ageDiffInMs = Date.now() - dob.getTime();
    const ageDate = new Date(ageDiffInMs);

    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

/**
 * Compares two dates to check if they are equal, ignoring the time (hours/minutes/seconds) of each date.
 * @param {date} date1
 * @param {date} date2
 */
export function datesAreEqual(date1, date2) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    d1.setUTCHours(0, 0, 0, 0);
    d2.setUTCHours(0, 0, 0, 0);

    return d1.getTime() === d2.getTime();
}

/**
 * Converts a date to DD/MM/YYYY string representation
 * @param {date} date
 */
export function dateToDDMMYYYY(date) {
    if (date instanceof Date) {
        return `${padFigure(date.getUTCDate())}/${padFigure(date.getUTCMonth() + 1)}/${date.getUTCFullYear()}`;
    }
    return undefined;
}

/**
 * Converts a DD/MM/YYYY date representation string to a date object
 * @param {string} dateString
 */
export function DDMMYYYYToDate(dateString) {
    if (/[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}/.test(dateString)) {
        const dateSplit = dateString.split('/');
        const date = new Date();
        date.setUTCFullYear(parseInt(dateSplit[2]), parseInt(dateSplit[1] - 1), parseInt(dateSplit[0]));
        date.setUTCHours(0, 0, 0, 0);
        return date;
    }
    return undefined;
}

/**
 * Returns the date and month (E.G. 1st January) for the given date
 * @param {date} date
 */
export function getDayAndMonthNameFromDate(date) {
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    return `${date.getDate()}${nth(date.getDate())} ${monthNames[date.getMonth()]}`;
}

/**
 * Returns the date, month and year (E.G. 1st January 2021) for the given date
 * @param {date} date
 */
export function getDayMonthYearFromDate(date) {
    return `${getDayAndMonthNameFromDate(date)} ${date.getFullYear()}`;
}

/**
 * Returns the day name (E.G. Monday) for the given date
 * @param {date} date
 */
export function getDayNameFromDate(date) {
    return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][date.getDay()];
}

/**
 * Takes the given date, adds the given number of days to it, and returns the new (departure) date
 * @param {date} arrivalDate - The starting (arrival) date
 * @param {number} duration - The number of days the holiday lasts for (the number of days to add to the arrival date)
 */
export const getDepartureDate = (arrivalDate, duration) => {
    const departureDate = new Date(arrivalDate);
    departureDate.setTime(departureDate.getTime() + parseInt(duration, 10) * 86400000);
    return departureDate;
};

/**
 * Returns a 'short date' string representation (E.G. 1-Jan-2020) for the given date
 * @param {date} date - Date to use
 * @param {string} delimiter - Character to use to separate the date parts
 */
export const getShortDateFormat = (date, delimiter = '-') => {
    let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    let day = date.getDate();

    let monthIndex = date.getMonth();
    let monthName = monthNames[monthIndex];

    let year = date.getFullYear();

    return `${day}${delimiter}${monthName}${delimiter}${year}`;
};

/**
 * Returns the 'ordinal indicator' for a date (E.G. 'st' for 1) for the given number
 * @param {number} number
 */
export function nth(number) {
    if (number > 3 && number < 21) return 'th';
    switch (number % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
}

/**
 * Converts a number to a string, witrh values lower that 10 startting with a 0 (E.G. 01)
 * @param {number} num
 */
export function padFigure(num) {
    const numString = num.toString();
    return numString.length === 1 ? '0' + numString : numString;
}

/**
 * Converts a unix time to a date object
 * @param {string | number} unixTime - The unix time to convert
 */
export function unixTimeToDate(unixTime) {
    return new Date(unixTime * 1000);
}
