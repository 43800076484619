import React from 'react';
import { Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import Checkbox from '../../../FormElements/InputCheckbox';
import PaypalFieldContainer from '../../../FormElements/PaypalFieldContainer';
import SelectInput from '../../../FormElements/SelectInput';
import TextInput from '../../../FormElements/TextInput';

import countries from '../../../../Data/Countries.json';
import { postcode, required } from '../../../../utils/validation';
import t from '../../../../../text';

export const validateFields = (values = {}, paypalFormState = {}) => {
    const errors = {};
    if (!required(values.name)) {
        errors.name = t('forms.paypalCard.invalidCardName');
    }
    if (values.billingAddress) {
        if (!required(values.addrLn1)) {
            errors.addrLn1 = t('forms.paypalCard.invalidAddress');
        }
        if (!required(values.town)) {
            errors.town = t('forms.paypalCard.invalidTown');
        }
        if (!required(values.postCode) || !postcode(values.postCode)) {
            errors.postCode = t('forms.paypalCard.invalidPostcode');
        }
        if (!required(values.county)) {
            errors.county = t('forms.paypalCard.invalidCounty');
        }
        if (!required(values.country)) {
            errors.country = t('forms.paypalCard.invalidCountry');
        }
    }

    const ppFields = paypalFormState.fields;
    if (!ppFields?.cvv?.isValid) {
        errors.cvv = t('forms.paypalCard.invalidSecurityNumber');
    }
    if (!ppFields?.expirationDate?.isValid) {
        errors.expirationDate = t('forms.paypalCard.invalidExpiryDate');
    }
    if (!ppFields?.number?.isValid) {
        errors.number = t('forms.paypalCard.invalidCardNumber');
    }

    return errors;
};

export const CardPaymentForm = ({ amount, busy, errors, onChange, onSubmit, values }) => (
    <>
        <Form name="cardPaymentForm" noValidate>
            <div className="mb-3">
                <strong>{t('forms.paypalCard.introText')}</strong>
            </div>

            <Form.Row>
                <TextInput
                    groupClassName="col-12 col-lg-6"
                    className="paypal-field"
                    name="name"
                    label={t('forms.paypalCard.nameOnCard')}
                    value={values.name}
                    attrs={{ disabled: busy }}
                    onChange={onChange ? (e) => onChange(e.target.name, e.target.value) : undefined}
                    invalid={!!errors.name}
                    validationMessage={errors.name}
                />
                <PaypalFieldContainer
                    groupClassName="col-12 col-lg-6"
                    containerId="cardNumContainer"
                    controlId="cardNum"
                    label={t('forms.paypalCard.cardNumber')}
                    invalid={!!errors.number}
                    validationMessage={errors.number}
                />
            </Form.Row>
            <Form.Row>
                <PaypalFieldContainer
                    groupClassName="col-12 col-lg-6"
                    containerId="expiryDateContainer"
                    controlId="expiryDate"
                    label={t('forms.paypalCard.expiryDate')}
                    invalid={!!errors.expirationDate}
                    validationMessage={errors.expirationDate}
                />
                <PaypalFieldContainer
                    groupClassName="col-12 col-lg-6"
                    containerId="cvvContainer"
                    controlId="cvv"
                    label={t('forms.paypalCard.securityCode')}
                    invalid={!!errors.cvv}
                    validationMessage={errors.cvv}
                />
            </Form.Row>
            <Form.Row className="mt-2">
                <Form.Group className="col-12" controlId="billingAddress">
                    <Checkbox
                        id="billingAddress"
                        label={t('forms.paypalCard.differentBillingAddress')}
                        checked={!!values.billingAddress}
                        onChange={onChange ? (checked) => onChange('billingAddress', checked) : undefined}
                    />
                </Form.Group>
            </Form.Row>
            {!!values.billingAddress && (
                <>
                    <Form.Row>
                        <TextInput
                            groupClassName="col"
                            className="paypal-field"
                            label={t('forms.address.address')}
                            name="addrLn1"
                            value={values.addrLn1}
                            onChange={(e) => onChange(e.target.name, e.target.value)}
                            invalid={!!errors.addrLn1}
                            validationMessage={errors.addrLn1}
                            attrs={{ disabled: busy }}
                        />
                    </Form.Row>

                    <Form.Row>
                        <TextInput
                            groupClassName="col"
                            className="paypal-field"
                            label={t('forms.address.town')}
                            name="town"
                            value={values.town}
                            onChange={(e) => onChange(e.target.name, e.target.value)}
                            invalid={!!errors.town}
                            validationMessage={errors.town}
                            attrs={{ disabled: busy }}
                        />
                        <TextInput
                            groupClassName="col"
                            className="paypal-field"
                            label={t('forms.address.county')}
                            name="county"
                            value={values.county}
                            onChange={(e) => onChange(e.target.name, e.target.value)}
                            invalid={!!errors.county}
                            validationMessage={errors.county}
                            attrs={{ disabled: busy }}
                        />
                    </Form.Row>

                    <Form.Row>
                        <TextInput
                            groupClassName="col"
                            className="paypal-field"
                            label={t('forms.address.postcode')}
                            name="postCode"
                            value={values.postCode}
                            onChange={(e) => onChange(e.target.name, e.target.value)}
                            attrs={{ maxLength: 8, disabled: busy }}
                            invalid={!!errors.postCode}
                            validationMessage={errors.postCode}
                        />

                        <SelectInput
                            groupClassName="col"
                            className="paypal-field"
                            label={t('forms.address.country')}
                            name="country"
                            value={values.country || ''}
                            onChange={(e) => onChange(e.target.name, e.target.value)}
                            invalid={!!errors.country}
                            validationMessage={errors.country}
                            attrs={{ disabled: busy }}
                        >
                            <option value="" disabled>
                                {t('forms.address.selectCountry')}
                            </option>
                            {Object.keys(countries).map((key) => (
                                <option key={key} value={countries[key].iso2}>
                                    {countries[key].label}
                                </option>
                            ))}
                        </SelectInput>
                    </Form.Row>
                </>
            )}
            <Button variant="primary" block className="mt-3" disabled={busy} onClick={onSubmit}>
                {busy ? t('forms.paypalCard.processing') : t('forms.paypalCard.confirmPaymentOf', amount.toFixed(2))}
            </Button>
        </Form>
        <div id="payments-sdk__contingency-lightbox"></div>
    </>
);

CardPaymentForm.defaultProps = {};

CardPaymentForm.propTypes = {
    amount: PropTypes.number,
    busy: PropTypes.bool,
    errors: PropTypes.object,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    values: PropTypes.object,
};

export default CardPaymentForm;
