import React from 'react';
import { Card } from 'react-bootstrap';

function AltAccommodation(props) {
    return (
        <Card>
            <Card.Header>
                <h2 className="m-0">{props.channel === 'boats' ? 'Boats' : 'Accommodation'}</h2>
            </Card.Header>
            <Card.Body className="p-3">
                <div dangerouslySetInnerHTML={{ __html: props.content }}></div>
            </Card.Body>
        </Card>
    );
}

export default AltAccommodation;
