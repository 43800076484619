import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { slugify } from '../../utils/string';
import t from '../../../text';
import RedEye from '../../components/RedEye/RedEye';
import { stripProtocol } from '../../utils/string';
import { config, urls } from '../../../config';
import A from '../A';
import { Button, Modal, Form, Col } from 'react-bootstrap';
import Logo from '../Logo';

export const ParkLink = ({ channelId, regionId, park, url, parkRoute, specialButtonClass, smallText, minPrice }) => {
    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [fName, setFName] = useState('');
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(0);
    const [message, setMessage] = useState('');
    const [displayRedEye, setDisplayRedEye] = useState(false);

    const handleShowPartnerPopup = () => setShow(true);
    const handleHidePartnerPopup = () => setShow(false);
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === true) {
            fetch(
                `${urls.newsletterSignup}?email=${encodeURIComponent(email)}&fname=${encodeURIComponent(
                    fName,
                )}&type=OPID-${park.opId}`,
            )
                .then((response) => response.json())
                .then((data) => {
                    setSuccess(data.success);
                    if (data.success === 1) setMessage('');
                    if (data.success === 0) {
                        setMessage(data.errors[0].message);
                        setDisplayRedEye(true);
                    }
                })
                .catch((e) => {
                    console.error('Could not fetch the content.', e);
                    setDisplayRedEye(true);
                });
        }

        setValidated(true);
        setDisplayRedEye(true);
    };

    const extLinkText = (minPrice, channelId) => {
        if (+minPrice >= 1) return t('searchResults.viewPark', channelId, minPrice);
        return t('searchResults.viewPark', channelId);
    };

    if (url) {
        return (
            <>
                <Button variant={`primary${' ' + specialButtonClass}${smallText ? ' smallText' : ''}`} block onClick={handleShowPartnerPopup}>
                    {extLinkText(minPrice, channelId)}
                </Button>
                <Modal show={show} centered onHide={handleHidePartnerPopup}>
                    <Modal.Header closeButton />
                    <Modal.Body className="p-4 text-center">
                        <Logo className="my-3" />
                        <p className="h3 mb-3">We are sending you to our trusted partner to complete your booking.</p>
                        <A
                            className={`btn btn-primary btn-block mt-3 mb-4 ${specialButtonClass}`}
                            href={url + `&utm_campaign=${config['siteCode']}`}
                        >
                            Continue to Booking
                        </A>
                    </Modal.Body>
                    <Modal.Footer className="p-4">
                        <p className="h5 text-center mb-3">
                            {t('parks.popup.newsletter')}
                        </p>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            {success !== 1 && (
                                <Form.Row className="justify-content-md-center">
                                    <Col xs={12}>
                                        <Form.Group controlId="FirstName">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control
                                                value={fName}
                                                onChange={(e) => setFName(e.target.value)}
                                                type="text"
                                                placeholder="First Name"
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please add your first name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group controlId="EmailAddress">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                type="email"
                                                placeholder="Email address"
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please choose a valid email address.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Button block variant="secondary" size="lg" type="submit">
                                            Subscribe
                                        </Button>
                                    </Col>
                                </Form.Row>
                            )}
                            {message.length > 0 && (
                                <Form.Row className="justify-content-md-center">
                                    <Col>
                                        <p className="text-center mt-2">
                                            <i className="icon-attention h4"></i> {message}
                                        </p>
                                    </Col>
                                </Form.Row>
                            )}

                            {success === 1 && (
                                <Form.Row className="justify-content-md-center">
                                    <Col xs={12}>
                                        <p className="h5 text-center">
                                            <i className="icon-check text-success circle-white"></i> Thank you for
                                            subscribing! You will now receive the latest holiday offers straight to your
                                            inbox
                                        </p>
                                    </Col>
                                </Form.Row>
                            )}

                            {displayRedEye && success === 1 && (
                                <RedEye
                                    params={{
                                        nourl: 'email-sign-up',
                                        newsletter: 'sign-up',
                                        email: email,
                                        emailpermit: success ? 'yes' : 'no',
                                        email_signup_url: stripProtocol(window.location.href),
                                    }}
                                />
                            )}
                            <p className="text-center mt-3">
                                <a href="/terms" target="_blank" rel="noopener noreferrer">
                                    <u>{t('footer.newsletter.termsAndConditions')}</u>
                                </a>{' '}
                                apply
                            </p>
                        </Form>
                    </Modal.Footer>
                </Modal>
                {/*
                <A
                    className={`btn btn-primary btn-block py-2 ${specialButtonClass}${smallText ? ' smallText' : ''}`}
                    href={url + `&utm_campaign=${config['siteCode']}`}
                >
                    {t('searchResults.viewPark', channelId)}
                </A>
                */}
            </>
        );
    }
    return (
        <Link
            to={`/${parkRoute}/${channelId}/${regionId}/${park.parkId}/${slugify(park.name || '')}`} // We should not show parks without data
            className={`btn btn-block py-2 ${specialButtonClass}${smallText ? ' smallText' : ''}`}
        >
            {t('searchResults.viewPark', channelId, minPrice)}
        </Link>
    );
};

ParkLink.propTypes = {
    channelId: PropTypes.string,
    regionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    park: PropTypes.object,
    url: PropTypes.string,
    parkRoute: PropTypes.string,
    specialButtonClass: PropTypes.string,
};

ParkLink.defaultProps = {
    parkRoute: 'park-result',
    specialButtonClass: 'btn-primary',
};

export default ParkLink;
