import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import t from '../../../text';
import logoBlack from '../../assets/images/logo-black.svg';

export const HeaderBar = ({ loggedIn, onLogout, onShowLogin }) => (
    <div className="header-bar bg-lighter border-bottom p-2 pl-4 d-flex justify-content-between flex-wrap">
        {!loggedIn ? (
            <>
                <button onClick={onShowLogin} className="remove-button-styling text-primary cursor-pointer mr-3">
                    <i className="icon-sign-in"></i> {t('header.signUp')}
                </button>
                <button onClick={onShowLogin} className="remove-button-styling text-primary cursor-pointer">
                    <i className="icon-login"></i> {t('header.login')}
                </button>
            </>
        ) : (
            <>
                <button onClick={onLogout} className="remove-button-styling text-primary cursor-pointer">
                    <i className="icon-previous"></i> {t('header.logout')}
                </button>
                <NavLink to="/my-account" className="ml-3 text-decoration-none">
                    <i className="icon-user"></i> {t('header.myAccount')}
                </NavLink>
            </>
        )}

        <div className='ml-auto'>
            <a href={`tel:${t('header.cs.tel')}`} className="mr-3">
                <i className="icon-phone"></i> {t('header.cs.tel')}
            </a>
            <span className="d-none d-sm-inline-block">
                <small>{t('header.cs.hours')}</small>
            </span>
        </div>
        <a href="https://uk.trustpilot.com/review/www.breakfreeholidays.co.uk" target="_blank" rel="noopener" className='d-none d-md-flex align-items-center ml-4'>
            See our reviews on <img src={logoBlack} alt="Logo black" width="90px" className='ml-1' />
        </a>
    </div>
);

HeaderBar.propTypes = {
    loggedIn: PropTypes.bool,
    onLogout: PropTypes.func,
    onShowLogin: PropTypes.func,
};

export default HeaderBar;
