import React, { useState, useEffect, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

const Slider = React.lazy(() => import('react-slick'));
//import Slider from 'react-slick';

function ParkDescription(props) {
    const videoAttributes = '?byline=0&title=0&transparent=0';
    const [videoUrl, setVideoUrl] = useState(props.video + videoAttributes);
    const [images, setImages] = useState();

    const resetVideo = () => {
        if (!props.video) return;
        setVideoUrl('');
    };

    useEffect(() => {
        setVideoUrl(props.video + videoAttributes);
    }, [videoUrl, props.video]);

    useEffect(() => {
        setImages(
            props.images.map((img, i) => (
                <img key={i + 1} className="img-fluid" src={img} alt={props.parkname} loading="lazy" />
            )),
        );
    }, [props.images, props.parkname]);

    return (
        <Container className="mb-5">
            <Row xs={1} md={2}>
                <Col onClick={() => resetVideo()} style={{ minHeight: '246px' }}>
                    <Suspense fallback={<img src="/img-loading.svg" width="100%" alt="Loading" />}>
                        <Slider className="full-height-slides" lazyLoad={true}>
                            {images}
                        </Slider>
                    </Suspense>
                </Col>
                <Col dangerouslySetInnerHTML={{ __html: props.description }}></Col>
                {/* Need to change this, return non-html or format it */}
            </Row>
        </Container>
    );
}

ParkDescription.propTypes = {
    description: PropTypes.string,
    images: PropTypes.array,
    parkname: PropTypes.string,
    video: PropTypes.string,
};

export default ParkDescription;
