import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import CardPanel from '../../../components/CardPanel';
import Icon from '../../../components/Icon';
import RedEyeBookingConfirmationTag from '../../../components/RedEye/RedEyeBookingConfirmationTag';
import WithHolidaySummary from '../../../components/Booking/WithHolidaySummary';
import { totalCostFull } from '../../../redux/booking/form/selectors';
import PageTitle from '../../../components/PageTitle';
import t from '../../../../text';
import { config } from '../../../../config';

export const Confirmation = ({ bookingType, confirmationId }) => {
    const isNewBooking = bookingType === 'new';
    const _totalCostFull = useSelector(totalCostFull);

    if (confirmationId) {
        if (config['awin.enable']) {
            const triggerAWIN = () => {
                if (!window.AWIN) {
                    console.info('No AWIN on window yet, trying again...');
                    return;
                } else {
                    console.info('Running AWIN.Tracking.Sale...');
                    clearInterval(CheckForAWIN);
                    window.AWIN.Tracking.Sale = {};
                    window.AWIN.Tracking.Sale.amount = _totalCostFull;
                    window.AWIN.Tracking.Sale.channel = 'aw';
                    window.AWIN.Tracking.Sale.orderRef = confirmationId;
                    window.AWIN.Tracking.Sale.parts = `default:${_totalCostFull}`;
                    window.AWIN.Tracking.Sale.currency = 'GBP';
                    window.AWIN.Tracking.Sale.voucher = '';
                    window.AWIN.Tracking.Sale.test = '0';
                    window.AWIN.Tracking.run();
                }
            };
            let CheckForAWIN = setInterval(triggerAWIN, 99);
        }

        return (
            <>
                <PageTitle title={t('booking.confirmation.thankYouFor', isNewBooking)} />
                <WithHolidaySummary confirmation>
                    <h1>
                        <Icon icon="logo" size="h1" theme="secondary" className="pr-2" />
                        {isNewBooking
                            ? t('booking.confirmation.holidayBooked')
                            : t('booking.confirmation.paymentReceived')}
                    </h1>
                    <h2 className="mb-5">
                        {t('booking.confirmation.yourBookingReferenceIs')} <strong>{confirmationId}</strong>
                    </h2>

                    <CardPanel title="What's next?">
                        <div>
                            <h2 className="text-dark">{t('booking.confirmation.bookAnotherBreak')}</h2>
                            <p>{t('booking.confirmation.bookAnotherBreakText')}</p>
                            <Link className="btn btn-primary" to="/">
                                {t('booking.confirmation.bookAgain')}
                            </Link>
                        </div>

                        {/* <div className='mt-4'>
                        <h2 className='text-dark'>Rate your experience</h2>
                        <p>How was your booking experience today? Please rate your experience below and send us any comments you may have.</p>
                        <Link className='btn btn-primary' to='/review'>Send a review</Link>
                    </div> */}

                        <div className="mt-4">
                            <h2 className="text-dark">{t('booking.confirmation.myAccount')}</h2>
                            <p>{t('booking.confirmation.myAccountText')}</p>
                            <Link className="btn btn-primary" to="/my-account">
                                {t('booking.confirmation.visitMyAccount')}
                            </Link>
                        </div>
                    </CardPanel>
                </WithHolidaySummary>

                {isNewBooking && <RedEyeBookingConfirmationTag />}

                {config['awin.enable'] && (
                    <img
                        alt="awin"
                        border={0}
                        height={0}
                        src={`https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=${
                            config['awin.trackingId']
                        }&amount=${_totalCostFull}&ch=aw&parts=DEFAULT:${_totalCostFull}&ref=${confirmationId}&cks=${
                            document.cookie.match('(^|;)\\s*_AWCToken\\s*=\\s*([^;]+)')?.pop() || ''
                        }&cr=GBP&vc=&testmode=0`}
                        style={{ display: 'none' }}
                        width={0}
                    />
                )}
            </>
        );
    } else {
        return (
            <>
                <Container>
                    <Row>
                        <Col>
                            <p className="mt-5">{t('booking.confirmation.noBookingConfirmation')}</p>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
};

export default Confirmation;
