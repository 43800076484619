import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import DayPicker from 'react-day-picker';
import { Form, Dropdown } from 'react-bootstrap';
import { getShortDateFormat } from '../../utils/date';
import t from '../../../text';

// Cottages
import { useDispatch, useSelector } from 'react-redux';
import { channelId, regionId, duration, currentCottageDates, cottageTTL } from '../../redux/search/form/selectors';
import { storeAllCottageDates } from '../../redux/search/form/actions';
import { config, urls } from '../../../config';

const getNightsOptions = (channelId) => {
    switch (channelId) {
        case 'ukcaravan':
            if (config['searchBox.allowFiveDays']) return [2, 3, 4, 5, 7, 10, 11, 14];
            else return [2, 3, 4, 7, 10, 11, 14];
        case 'uktouring':
            return [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];
        default:
            return [2, 3, 4, 7, 10, 11, 14];
    }
};

// "2021-04-25" => new Date(2021, 3, 25)
// This is required by the react day picker api
const formatStringDatesToNewDates = (dates) => {
    return dates.map((date) => {
        const dateSplit = date.split('-');
        return new Date(+dateSplit[0], +dateSplit[1] - 1, +dateSplit[2]);
    });
};

const getUnavailableDaysDefault = (duration = 0) => {
    switch (duration) {
        case 2:
            return [0, 1, 2, 3, 4, 5]; // Allow Saturdays only
        case 3:
        case 10:
            return [0, 1, 2, 3, 4, 6]; // Allow Fridays only
        case 4:
        case 11:
            return [0, 2, 3, 4, 5, 6]; // Allow Mondays only
        case 5:
            return [0, 1, 3, 5, 6];
        case 7:
        case 14:
            return [0, 2, 3, 4]; // Allow Mondays, Fridays, Saturdays
        default:
            return [0, 1, 2, 3, 4, 5, 6]; // Otherwise disable all days
    }
};

export const DateDurationSelect = (props) => {
    const dispatch = useDispatch();

    const [disabledDaysObject, setDisabledDaysObject] = useState({});
    const [disabledDatesArray, setDisabledDatesArray] = useState([]);
    const [firstRun, setFirstRun] = useState(true);

    const _channelId = useSelector(channelId);
    const _regionId = useSelector(regionId);
    const _duration = useSelector(duration);
    const _currentCottageDates = useSelector(currentCottageDates);
    const _cottageTTL = useSelector(cottageTTL);

    useEffect(() => {
        getDateAndDurationText();
    }, [props.arrivalDate, props.duration]);

    const fetchCottageDataAndUpdateUnavailableDates = useCallback(() => {
        const timeNow = new Date();
        // Check if data exists, and ttl isnt expired
        if (_currentCottageDates && _currentCottageDates.length && _cottageTTL > timeNow) {
            setDisabledDatesArray(_currentCottageDates);
        } else {
            // do fetch request
            fetch(urls.cottageDates)
                .then((response) => response.json())
                .then((data) => {
                    const cottageDates = JSON.parse(data.data);
                    const today = new Date();

                    if (_regionId in cottageDates === false) cottageDates[_regionId] = [(today.getFullYear() + '-') + ((today.getMonth() + 1) <= 9 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1) + '-' + today.getDate()];
                    for (const [key, value] of Object.entries(cottageDates)) {
                        cottageDates[key] = formatStringDatesToNewDates(value);
                    }
                    storeAllCottageDates(dispatch, cottageDates);
                    setDisabledDatesArray(cottageDates[_regionId]);
                });
        }
    }, [_cottageTTL, _currentCottageDates, _regionId, dispatch]);

    const setUnavailableDaysAndDates = useCallback(() => {
        if (_regionId === undefined) return;

        let unavailableDays = [];
        setDisabledDaysObject({});
        setDisabledDatesArray([]);

        switch (_channelId) {
            case 'boats':
            case 'uktouring':
                break;
            case 'ukcottages':
                fetchCottageDataAndUpdateUnavailableDates();
                break;
            default:
                unavailableDays = getUnavailableDaysDefault(_duration);
                setDisabledDaysObject({ daysOfWeek: unavailableDays });
        }
    }, [_channelId, _duration, _regionId, fetchCottageDataAndUpdateUnavailableDates]);

    useEffect(() => {
        setUnavailableDaysAndDates();
    }, [_channelId, _regionId, _duration, setUnavailableDaysAndDates]);

    if (firstRun) {
        setFirstRun(false);
        setUnavailableDaysAndDates();
    }

    const getDateAndDurationText = (arrivalDate, duration) => {
        if (duration && arrivalDate) {
            return t('search.dateNights', getShortDateFormat(new Date(arrivalDate)), duration);
        } else {
            return t('search.selectADate');
        }
    };

    return (
        <Form.Group controlId={props.id}>
            <Form.Label className={`text-${props.textStyle}`}>{props.label}</Form.Label>
            <Dropdown show={props.show} onToggle={props.onToggle} drop="down">
                <Dropdown.Toggle
                    as="button"
                    type="button"
                    className="toggle-popup is-calendar"
                    role="button"
                    tabIndex="0"
                    disabled={props.disabled} // add || isCalendarDisabled when reactivated
                >
                    <span>{getDateAndDurationText(props.arrivalDate, props.duration)}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="a-popup no-max-height p-0">
                    <div className="bg-light border-bottom p-3">
                        <h5>{t('search.stayingForHowLong')}</h5>
                        <Form.Group controlId={props.id + '-nights'} className="mb-0">
                            <Form.Control
                                as="select"
                                value={parseInt(props.duration)}
                                onChange={props.onDurationChange}
                            >
                                <option value="" disabled>
                                    {t('search.numberOfNights')}
                                </option>
                                {getNightsOptions(props.channelId).map((val) => (
                                    <option key={val} value={val}>
                                        {t('search.xNights', val)}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </div>
                    <div className="p-3">
                        <h5>{t('search.selectAnArrivalDate')}</h5>
                        <DayPicker
                            fromMonth={new Date()}
                            onDayClick={props.onDateChange}
                            initialMonth={props.arrivalDate ? new Date(props.arrivalDate) : undefined}
                            selectedDays={new Date(props.arrivalDate)}
                            disabledDays={[{ before: new Date() }, disabledDaysObject, ...disabledDatesArray]}
                        />
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </Form.Group>
    );
};

DateDurationSelect.defaultProps = {};

DateDurationSelect.propTypes = {
    id: PropTypes.string,
    channelId: PropTypes.string,
    textStyle: PropTypes.string,
    label: PropTypes.string,
    duration: PropTypes.number,
    arrivalDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    onDurationChange: PropTypes.func,
    onDateChange: PropTypes.func,
    onToggle: PropTypes.func,
    show: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default DateDurationSelect;
