import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isReady, isFetching, hasError } from '../../redux/init/selectors';
import { appInit, getInitData } from '../../redux/init/actions';
import { getNav } from '../../redux/header/actions';

import t from '../../../text';
import WithLoadingStates from './WithLoadingStates';

export const Init = (props) => {
    const appIsReady = useSelector(isReady);
    const appIsFetching = useSelector(isFetching);
    const appHasError = useSelector(hasError);
    const dispatch = useDispatch();
    const [firstRun, setFirstRun] = useState(true);

    if (firstRun) {
        setFirstRun(false);
        appInit(dispatch); // Super important - Causes request statuses to be reset (amongst other things)
    }

    useEffect(() => {
        getInitData(dispatch);
        getNav(dispatch);
    }, [dispatch]);

    return (
        <WithLoadingStates
            isFetching={appIsFetching}
            hasErrorFetching={appHasError}
            loadingErrorMessage={t('init.errorLoading')}
            onRetry={() => getInitData(dispatch, true)}
            useContainer={true}
        >
            {appIsReady && props.children}
        </WithLoadingStates>
    );
};

export default Init;
