import { text as defaultText } from '../../base/defaultText';
import { getTranslation } from '../../base/utils/i18n';

const text = {
    ...defaultText,
    'footer.nav.companyName': 'Mail Holidays',
    'footer.nav.tagline': 'Mail Holiday Parks is operated by Breakfree Holidays - registered in England No. 05753643 | Copyright © Breakfree Holidays. All rights reserved.',
    'footer.trust.whyChooseUs': 'Why choose Mail Travel?',
    'forms.contactPrefs.emailNewspaperOffers': 'Use my email address to be contacted by Mail Travel',
    'header.cs.tel': '0333 220 1937',
};

/**
 * Fetches a text string from the available text pack
 * @param {string} key - The key of the text string to retrieve
 * @param  {...any} params - Any parameters to pass to your string function. Optional.
 */
export const t = (key, ...params) => getTranslation(key, text, params);
export default t;
