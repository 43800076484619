import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import InputCheckbox from '../../../FormElements/InputCheckbox';
import InputNumber from '../../../FormElements/InputNumber';

export const ExtrasFormOption = (props) => {
    const cost = parseFloat(props.cost).toFixed(2);

    return (
        <Form.Group id={props.code} className={props.className}>
            {props.desc && <div className="mb-2" dangerouslySetInnerHTML={{ __html: props.desc }} />}
            <div
                className={`form-elements-wrapper ${props.type === 'boolean' ? 'form-elements-wrapper--can-grow' : ''}`}
            >
                {props.type === 'boolean' ? (
                    <InputCheckbox
                        id={props.code}
                        label={`${props.name}: <b>£${cost}</b>`}
                        ariaLabel={`${props.name}: £${cost}`}
                        checked={!!props.value}
                        onChange={(val) => props.onChange(props.code, Number(val))}
                    />
                ) : (
                    <>
                        <p className="mb-1">
                            {props.name} : <b>{`${props.code === 'extLUXPET' ? '' : `£${cost}`}`}</b>
                        </p>
                        <InputNumber
                            id={props.code}
                            min={parseInt(props.min, 10)}
                            max={parseInt(props.max, 10)}
                            value={props.value}
                            onChange={(val) => props.onChange(props.code, val)}
                        />
                    </>
                )}
            </div>
        </Form.Group>
    );
};

ExtrasFormOption.defaultProps = {};

ExtrasFormOption.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    type: PropTypes.string,
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    code: PropTypes.string,
    name: PropTypes.string,
    cost: PropTypes.string,
    dep: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    desc: PropTypes.string,
    value: PropTypes.number,
};

export default ExtrasFormOption;
