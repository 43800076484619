import React from 'react';
import { Button, Card, Col, Row, Tab } from 'react-bootstrap';
import useFetchData from '../TravelHooks/useFetchData';

const PopularLocations = ({ popLocData }) => {
    const posts = useFetchData(popLocData, 'popular-locations');

    return (
        <Tab.Pane eventKey="popular-locations">
            <Card className="border">
                <Card.Header>
                    <h2>Popular Locations</h2>
                </Card.Header>
                <Card.Body className="p-3">
                    <Row xs={1} md={2} noGutters>
                        {posts.map((pl, i) => {
                            return (
                                <Col key={i} className="p-3">
                                    <img src={pl.acf.image} alt={pl.title.rendered} className="w-100 mb-2" />
                                    <h2 dangerouslySetInnerHTML={{ __html: pl.title.rendered }} />
                                    <p>{pl.acf.description}</p>
                                    <Button href={pl.acf.url} target="_blank" block>
                                        Read more
                                    </Button>
                                </Col>
                            );
                        })}
                    </Row>
                </Card.Body>
            </Card>
        </Tab.Pane>
    );
};

export default PopularLocations;
