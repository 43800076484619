import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { urls } from '../../../config';
import TruncatedTextAlt from '../TruncatedTextAlt';

function FetchSeoSearchText(props) {
    const [hasText, setHasText] = useState(false);
    const [seoText, setSeoText] = useState(props.region);

    const Content = (seoText) => {
        return (
            <Container>
                <Row>
                    <Col>
                        <TruncatedTextAlt text={seoText} className="mb-4" />
                    </Col>
                </Row>
            </Container>
        );
    };

    useEffect(() => {
        const controller = new AbortController();
        fetch(
            `${urls.cms}/region-text?per_page=1&_fields=acf.seo_text,acf.area_seo_text&filter[meta_query][1][key]=region_id&filter[meta_query][1][value]=${props.region}`,
            { signal: controller.signal }
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.length > 0) {
                    if (
                        props.isArea &&
                        'area_seo_text' in data[0].acf &&
                        data[0].acf.area_seo_text &&
                        data[0].acf.area_seo_text.some((a) => a.area_id === props.isArea)
                    ) {
                        setSeoText(
                            <p className="pt-3">
                                {data[0].acf.area_seo_text.find((a) => a.area_id === props.isArea).area_text}
                            </p>,
                        );
                    } else {
                        setSeoText(<p className="pt-3">{data[0].acf.seo_text}</p>);
                    }
                    setHasText(true);
                }
            });
        return () => {
            controller.abort();
        };
    }, [props.region, props.isArea]);

    return hasText ? Content(seoText) : null;
}

export default FetchSeoSearchText;
