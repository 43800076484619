import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import HolidayDateCard from './HolidayDateCard';
import { getDayNameFromDate, getDayAndMonthNameFromDate } from '../../../utils/date';
import t from '../../../../text';
import { requestPayload } from '../../../redux/search/results/selectors/selectors';
//import AltDatesCalendar from './AltDatesCalendar';
//import { config } from '../../../../config';

const sortByDate = (arr) => {
    return arr.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
    });
};

function AlternativeDates(props) {
    //const [cottageAltDates, setCottageAltDates] = useState(false);
    const searchDate = useSelector(requestPayload).date;

    // Find the preferred date park data
    const allParks = props.results;
    const preferredDate = allParks[searchDate];

    // Find the alternative date parks data
    const allDates = Object.values(props.results);
    const filteredDates = allDates.filter((date) => date !== preferredDate);
    const alternativeDates = sortByDate(filteredDates);

    return (
        alternativeDates.length !== 0 && (
            <Container fluid className="bg-lighter border">
                <Container className="py-4">
                    <Row className="align-items-start single-date" noGutters>
                        {preferredDate && (
                            <Col md={3} className="pr-xl-5">
                                <p className="mb-3 font-weight-bold text-nowrap">{t('park.dates.yourPreferredDate')}</p>
                                <HolidayDateCard
                                    selected={preferredDate === props.selectedResult}
                                    day={getDayNameFromDate(preferredDate.date)}
                                    date={getDayAndMonthNameFromDate(preferredDate.date)}
                                    year={preferredDate.date.toString().slice(11, 15)}
                                    price={preferredDate.lowestPrice}
                                    clicked={() => props.onSelect(preferredDate)}
                                    preferred={true}
                                />
                            </Col>
                        )}

                        <Col md={preferredDate ? { span: 8, offset: 1 } : { span: 12 }}>
                            <Row>
                                <Col>
                                    <p className="mb-3 font-weight-bold">{t('park.dates.alternativeDates')}</p>
                                </Col>
                            </Row>
                            <Row noGutters>
                                {alternativeDates.map((result) => (
                                    <Col
                                        md={6}
                                        lg={!preferredDate ? { span: 3 } : { span: 4 }}
                                        xl={!preferredDate && { span: 3 }}
                                        key={result.date}
                                    >
                                        <HolidayDateCard
                                            selected={result === props.selectedResult}
                                            day={getDayNameFromDate(result.date)}
                                            date={getDayAndMonthNameFromDate(result.date)}
                                            year={result.date.toString().slice(11, 15)}
                                            price={result.lowestPrice}
                                            clicked={() => props.onSelect(result)}
                                        />
                                    </Col>
                                ))}
                            </Row>
                            {/*cottageAltDates && (
                                <Row>
                                    <Col>
                                        <AltDatesCalendar pid={props.parkId} duration={4} data={cottageAltDates} />
                                    </Col>
                                </Row>
                            )*/}
                        </Col>
                    </Row>
                </Container>
            </Container>
        )
    );
}

AlternativeDates.propTypes = {
    onSelect: PropTypes.func,
    results: PropTypes.object,
    selectedResult: PropTypes.object,
};

AlternativeDates.defaultProps = {};

export default AlternativeDates;
