import React, { useState, useEffect } from 'react';
import _debounce from 'lodash.debounce';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ColumnSlider from './ColumnSlider';
import { config } from '../../../config';
import A from '../A';

const BREAKPOINTS = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
};

function ColumnComponent({ heading, columns, parent }) {
    const [width, setWidth] = useState(window.innerWidth);
    const noOfItems = columns.length;
    //const imageHeightClass = noOfItems > 3 ? 'image-height-3' : `image-height-${noOfItems}`;
    let imageClasses = ['column-image', 'fixed-image-height'];

    const regionPage = parent === 'opRegions';
    if (regionPage) imageClasses.push('img-max-height');

    const extraClasses = imageClasses.join(' ');

    const sliderComponent = (
        <Col className={`${regionPage ? 'd-block' : ''}`}>
            <ColumnSlider items={columns} parent={parent} extraClasses={extraClasses} />
        </Col>
    );
    const columnComponent = columns.map((c, index) => {
        return (
            <Col key={index} sm={12 / noOfItems} className="d-flex">
                <Card className="flex-grow-1 home-column">
                    {/^http/.test(c.url) ? (
                        <A href={c.url} className="text-decoration-none d-block">
                            <Card.Img className={extraClasses} src={c.image} alt={c.title} loading="lazy" />
                            <Card.Body className="p-3">
                                <h3 className={`h3 m-0 text-${config['global.textHighlight']}`}>{c.title}</h3>
                                <p className="text-darker m-0">{c.text}</p>
                            </Card.Body>
                        </A>
                    ) : (
                        <Link to={c.url} className="text-decoration-none">
                            <Card.Img className={extraClasses} src={c.image} alt={c.title} loading="lazy" />
                            <Card.Body className="p-3">
                                <h3 className={`h3 m-0 text-${config['global.textHighlight']}`}>{c.title}</h3>
                                <p className="text-darker m-0">{c.text}</p>
                            </Card.Body>
                        </Link>
                    )}
                </Card>
            </Col>
        );
    });

    useEffect(() => {
        const handleWindowResize = _debounce(() => setWidth(window.innerWidth), 100);
        window.addEventListener('resize', handleWindowResize);

        // Return a function from the effect that removes the event listener
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    const getComponent = () => {
        const max = regionPage ? 3 : 4;
        if (
            noOfItems > max ||
            (noOfItems === 4 && width < BREAKPOINTS.lg) ||
            (noOfItems === 3 && width < BREAKPOINTS.md) ||
            (noOfItems === 2 && width < BREAKPOINTS.sm)
        ) {
            return sliderComponent;
        } else {
            return columnComponent;
        }
    };

    if (regionPage) return <Row>{getComponent()}</Row>;

    return (
        <Container className="my-3 p-3 p-lg-4">
            <Container>
                <Row>
                    {parent !== 'opRegions' && (
                        <Col xs={12}>
                            <h2 className="h1 text-center my-3">{heading}</h2>
                        </Col>
                    )}
                    {getComponent()}
                </Row>
            </Container>
        </Container>
    );
}

ColumnComponent.defaultProps = {
    heading: 'No title set',
    columns: [
        {
            image: 'https://placeimg.com/640/320/nature',
            title: 'Column One Title',
            text: 'Some text goes here',
            url: '#',
        },
        {
            image: 'https://placeimg.com/640/320/nature',
            title: 'Column Two Title',
            text: 'Some text goes here',
            url: '#',
        },
    ],
};

export default ColumnComponent;
